export const thaiHungarianDictionary = [
  /* {
    question: 'อร่อย',
    answer: 'finom',
    acceptableAnswers: [
      'ízletes',
    ],
  },
  {
    question: 'ผู้หญิง',
    description: 'Főnév, személy',
    answer: 'Nő',
    acceptableAnswers: [],
  },
  {
    question: 'ผูชๅย',
    description: 'Főnév, személy',
    answer: 'Férfi',
    acceptableAnswers: [],
  },
  {
    question: 'เด็กผู้หญิง',
    description: 'Főnév, személy',
    answer: 'Kislány',
    acceptableAnswers: [],
  },
  {
    question: 'เด็กผู้ชาย',
    description: 'Főnév, személy',
    answer: 'Kisfiú',
    acceptableAnswers: [],
  },
  {
    question: 'ยี่สิบห้า',
    description: 'Számmal',
    answer: '25',
    acceptableAnswers: [],
  },
  {
    question: 'สิบสอง',
    description: 'Számmal',
    answer: '12',
    acceptableAnswers: [],
  },
  {
    question: 'สิบสี่',
    description: 'Számmal',
    answer: '14',
    acceptableAnswers: [],
  },
  {
    question: 'สีสิบ',
    description: 'Számmal',
    answer: '40',
    acceptableAnswers: [],
  },
  {
    question: 'มา',
    description: '',
    answer: 'Jönni',
    acceptableAnswers: [],
  },
  {
    question: 'ผม',
    description: '',
    answer: 'Én',
    acceptableAnswers: [],
  },
  {
    question: 'จาก',
    description: '',
    answer: '-ról',
    acceptableAnswers: [],
  },
  {
    question: 'ประเทศ',
    description: '',
    answer: 'Ország',
    acceptableAnswers: [],
  },
  {
    question: 'ประเทศจีน',
    description: '',
    answer: 'Kína',
    acceptableAnswers: [],
  },
  {
    question: 'อังกฦษ',
    description: '',
    answer: 'Angol',
    acceptableAnswers: [],
  },
  {
    question: 'เยอรมัน',
    description: '',
    answer: 'Német',
    acceptableAnswers: [],
  },
  {
    question: 'พูด',
    description: '',
    answer: 'Beszél',
    acceptableAnswers: [],
  },
  {
    question: 'ภาษา',
    description: '',
    answer: 'Nyelv',
    acceptableAnswers: [],
  },
  {
    question: 'อายุ',
    description: '',
    answer: 'Idős',
    acceptableAnswers: [],
  },
  {
    question: 'ปึ',
    description: '',
    answer: 'Év',
    acceptableAnswers: [],
  }, */
];

import React from 'react';
import CategoryList from './../components/categoryList/components/CategoryList';

export default class Main extends React.Component {
  render() {
    return (
      <React.Fragment>
        <CategoryList />
      </React.Fragment>
    );
  }
}

export const hungarianThaiDictionary = [
  /**
   * TO GIVE - DAR
   */
  {
    question: 'adni',
    description: '',
    answer: 'dar',
    category: 'to-give',
    acceptableAnswers: [],
  },
  {
    question: 'adok',
    description: 'jelen',
    answer: 'doy',
    category: 'to-give',
    acceptableAnswers: [],
  },
  {
    question: 'adsz',
    description: 'jelen',
    answer: 'das',
    category: 'to-give',
    acceptableAnswers: [],
  },
  {
    question: 'ad',
    description: 'jelen',
    answer: 'da',
    category: 'to-give',
    acceptableAnswers: [],
  },
  {
    question: 'adunk',
    description: 'jelen',
    answer: 'damos',
    category: 'to-give',
    acceptableAnswers: [],
  },
  {
    question: 'adtok',
    description: 'jelen',
    answer: 'dais',
    category: 'to-give',
    acceptableAnswers: [],
  },
  {
    question: 'adnak',
    description: 'jelen',
    answer: 'dan',
    category: 'to-give',
    acceptableAnswers: [],
  },
  {
    question: 'adtam',
    description: 'egyszerű múlt',
    answer: 'di',
    category: 'to-give',
    acceptableAnswers: [],
  },
  {
    question: 'adtál',
    description: 'egyszerű múlt',
    answer: 'diste',
    category: 'to-give',
    acceptableAnswers: [],
  },
  {
    question: 'adott',
    description: 'egyszerű múlt',
    answer: 'dio',
    category: 'to-give',
    acceptableAnswers: [],
  },
  {
    question: 'adtunk',
    description: 'egyszerű múlt',
    answer: 'dimos',
    category: 'to-give',
    acceptableAnswers: [],
  },
  {
    question: 'adtatok',
    description: 'egyszerű múlt',
    answer: 'disteis',
    category: 'to-give',
    acceptableAnswers: [],
  },
  {
    question: 'adtak',
    description: 'egyszerű múlt',
    answer: 'dieron',
    category: 'to-give',
    acceptableAnswers: [],
  },

  /**
   * GO OUT - SALIR
   */
  {
    question: 'kimenni',
    description: '',
    answer: 'salir',
    category: 'go-out',
    acceptableAnswers: [],
  },
  {
    question: 'én kimegyek',
    description: 'jelen',
    answer: 'salgo',
    category: 'go-out',
    acceptableAnswers: [],
  },
  {
    question: 'te kimész',
    description: 'jelen',
    answer: 'sales',
    category: 'go-out',
    acceptableAnswers: [],
  },
  {
    question: 'ő kimegy',
    description: 'jelen',
    answer: 'sale',
    category: 'go-out',
    acceptableAnswers: [],
  },
  {
    question: 'mi kimegyünk',
    description: 'jelen',
    answer: 'salimos',
    category: 'go-out',
    acceptableAnswers: [],
  },
  {
    question: 'ti kimentek',
    description: 'jelen',
    answer: 'salís',
    category: 'go-out',
    acceptableAnswers: [],
  },
  {
    question: 'ők kimennek',
    description: 'jelen',
    answer: 'salen',
    category: 'go-out',
    acceptableAnswers: [],
  },
  {
    question: 'én kimentem',
    description: 'egyszerű múlt',
    answer: 'salí',
    category: 'go-out',
    acceptableAnswers: [],
  },
  {
    question: 'te kimentél',
    description: 'egyszerű múlt',
    answer: 'saliste',
    category: 'go-out',
    acceptableAnswers: [],
  },
  {
    question: 'ő kiment',
    description: 'egyszerű múlt',
    answer: 'salió',
    category: 'go-out',
    acceptableAnswers: [],
  },
  {
    question: 'mi kimentünk',
    description: 'egyszerű múlt',
    answer: 'salimos',
    category: 'go-out',
    acceptableAnswers: [],
  },
  {
    question: 'ti kimentetek',
    description: 'egyszerű múlt',
    answer: 'salisteis',
    category: 'go-out',
    acceptableAnswers: [],
  },
  {
    question: 'ők kimentek',
    description: 'egyszerű múlt',
    answer: 'salieron',
    category: 'go-out',
    acceptableAnswers: [],
  },

  /**
   * TO BE - SER
   */
  {
    question: 'Lenni',
    description: 'létige',
    answer: 'ser',
    category: 'to-be-ser',
    acceptableAnswers: [],
  },
  {
    question: 'én vagyok',
    description: 'jelen',
    answer: 'soy',
    category: 'to-be-ser',
    acceptableAnswers: [],
  },
  {
    question: 'te vagy',
    description: 'jelen',
    answer: 'eres',
    category: 'to-be-ser',
    acceptableAnswers: [],
  },
  {
    question: 'ő van',
    description: 'jelen',
    answer: 'es',
    category: 'to-be-ser',
    acceptableAnswers: [],
  },
  {
    question: 'mi vagyunk',
    description: 'jelen',
    answer: 'somos',
    category: 'to-be-ser',
    acceptableAnswers: [],
  },
  {
    question: 'ti vagytok',
    description: 'jelen',
    answer: 'sois',
    category: 'to-be-ser',
    acceptableAnswers: [],
  },
  {
    question: 'ők vannak',
    description: 'jelen',
    answer: 'son',
    category: 'to-be-ser',
    acceptableAnswers: [],
  },
  {
    question: 'én voltam',
    description: 'egyszerű múlt',
    answer: 'fui',
    category: 'to-be-ser',
    acceptableAnswers: [],
  },
  {
    question: 'te voltál',
    description: 'egyszerű múlt',
    answer: 'fuiste',
    category: 'to-be-ser',
    acceptableAnswers: [],
  },
  {
    question: 'ő volt',
    description: 'egyszerű múlt',
    answer: 'fue',
    category: 'to-be-ser',
    acceptableAnswers: [],
  },
  {
    question: 'mi voltunk',
    description: 'egyszerű múlt',
    answer: 'fuimos',
    category: 'to-be-ser',
    acceptableAnswers: [],
  },
  {
    question: 'ti voltatok',
    description: 'egyszerű múlt',
    answer: 'fuisteis',
    category: 'to-be-ser',
    acceptableAnswers: [],
  },
  {
    question: 'ők voltak',
    description: 'egyszerű múlt',
    answer: 'fueron',
    category: 'to-be-ser',
    acceptableAnswers: [],
  },

  /**
   * TO BE - ESTAR
   */
  {
    question: 'lenni (ideiglenesen)',
    description: '',
    answer: 'estar',
    category: 'to-be-estar',
    acceptableAnswers: [],
  },
  {
    question: 'én vagyok',
    description: '',
    answer: 'estoy',
    category: 'to-be-estar',
    acceptableAnswers: [],
  },
  {
    question: 'te vagy',
    description: '',
    answer: 'estás',
    category: 'to-be-estar',
    acceptableAnswers: [],
  },
  {
    question: 'ő van',
    description: '',
    answer: 'está',
    category: 'to-be-estar',
    acceptableAnswers: [],
  },
  {
    question: 'mi vagyunk',
    description: '',
    answer: 'estamos',
    category: 'to-be-estar',
    acceptableAnswers: [],
  },
  {
    question: 'ti vagytok',
    description: '',
    answer: 'estáis',
    category: 'to-be-estar',
    acceptableAnswers: [],
  },
  {
    question: 'ők vannak',
    description: '',
    answer: 'están',
    category: 'to-be-estar',
    acceptableAnswers: [],
  },
  {
    question: 'én voltam',
    description: '',
    answer: 'estuve',
    category: 'to-be-estar',
    acceptableAnswers: [],
  },
  {
    question: 'te voltál',
    description: '',
    answer: 'estuviste',
    category: 'to-be-estar',
    acceptableAnswers: [],
  },
  {
    question: 'ő volt',
    description: '',
    answer: 'estuvo',
    category: 'to-be-estar',
    acceptableAnswers: [],
  },
  {
    question: 'mi voltunk',
    description: '',
    answer: 'estuvimos',
    category: 'to-be-estar',
    acceptableAnswers: [],
  },
  {
    question: 'ti voltatok',
    description: '',
    answer: 'estuvisteis',
    category: 'to-be-estar',
    acceptableAnswers: [],
  },
  {
    question: 'ők voltak',
    description: '',
    answer: 'estuvieron',
    category: 'to-be-estar',
    acceptableAnswers: [],
  },

  /**
   * CAN - PUEDO
   */
  {
    question: 'tudni',
    description: '',
    answer: 'poder',
    category: 'can',
    acceptableAnswers: [],
  },
  {
    question: 'én tudok',
    description: '',
    answer: 'puedo',
    category: 'can',
    acceptableAnswers: [],
  },
  {
    question: 'te tudsz',
    description: '',
    answer: 'puedes',
    category: 'can',
    acceptableAnswers: [],
  },
  {
    question: 'ő tud',
    description: '',
    answer: 'puede',
    category: 'can',
    acceptableAnswers: [],
  },
  {
    question: 'mi tudunk',
    description: '',
    answer: 'podemos',
    category: 'can',
    acceptableAnswers: [],
  },
  {
    question: 'ti tudtok',
    description: '',
    answer: 'podéis',
    category: 'can',
    acceptableAnswers: [],
  },
  {
    question: 'ők tudnak',
    description: '',
    answer: 'pueden',
    category: 'can',
    acceptableAnswers: [],
  },
  {
    question: 'én tudtam',
    description: '',
    answer: 'pude',
    category: 'can',
    acceptableAnswers: [],
  },
  {
    question: 'te tudtál',
    description: '',
    answer: 'pudiste',
    category: 'can',
    acceptableAnswers: [],
  },
  {
    question: 'ő tudott',
    description: '',
    answer: 'pudo',
    category: 'can',
    acceptableAnswers: [],
  },
  {
    question: 'mi tudtunk',
    description: '',
    answer: 'pudimos',
    category: 'can',
    acceptableAnswers: [],
  },
  {
    question: 'ti tudtatok',
    description: '',
    answer: 'pudisteis',
    category: 'can',
    acceptableAnswers: [],
  },
  {
    question: 'ők tudtak',
    description: '',
    answer: 'pudieron',
    category: 'can',
    acceptableAnswers: [],
  },

  /**
   * TO HAVE - TENER
   */
  {
    question: 'birtokolni',
    description: '',
    answer: 'tener',
    category: 'to-have',
    acceptableAnswers: [],
  },
  {
    question: 'nekem van',
    description: 'jelen',
    answer: 'tengo',
    category: 'to-have',
    acceptableAnswers: [],
  },
  {
    question: 'neked van',
    description: 'jelen',
    answer: 'tienes',
    category: 'to-have',
    acceptableAnswers: [],
  },
  {
    question: 'neki van',
    description: 'jelen',
    answer: 'tiene',
    category: 'to-have',
    acceptableAnswers: [],
  },
  {
    question: 'nekünk van',
    description: 'jelen',
    answer: 'tenemos',
    category: 'to-have',
    acceptableAnswers: [],
  },
  {
    question: 'nektek van',
    description: 'jelen',
    answer: 'tenéis',
    category: 'to-have',
    acceptableAnswers: [],
  },
  {
    question: 'nekik van',
    description: 'jelen',
    answer: 'tienen',
    category: 'to-have',
    acceptableAnswers: [],
  },
  {
    question: 'nekem volt',
    description: 'egyszerű múlt',
    answer: 'tuve',
    category: 'to-have',
    acceptableAnswers: [],
  },
  {
    question: 'neked volt',
    description: 'egyszerű múlt',
    answer: 'tuviste',
    category: 'to-have',
    acceptableAnswers: [],
  },
  {
    question: 'ő neki volt',
    description: 'egyszerű múlt',
    answer: 'tuvo',
    category: 'to-have',
    acceptableAnswers: [],
  },
  {
    question: 'mi nekünk volt',
    description: 'egyszerű múlt',
    answer: 'tuvimos',
    category: 'to-have',
    acceptableAnswers: [],
  },
  {
    question: 'ti nektek volt',
    description: 'egyszerű múlt',
    answer: 'tuvisteis',
    category: 'to-have',
    acceptableAnswers: [],
  },
  {
    question: 'ők nekik volt',
    description: 'egyszerű múlt',
    answer: 'tuvieron',
    category: 'to-have',
    acceptableAnswers: [],
  },

  /**
   * TO COME - VENIR
   */
  {
    question: 'jönni',
    description: 'létige',
    answer: 'venir',
    category: 'to-come',
    acceptableAnswers: [],
  },
  {
    question: 'én jövök',
    description: 'jelen',
    answer: 'vengo',
    category: 'to-come',
    acceptableAnswers: [],
  },
  {
    question: 'te jössz',
    description: 'jelen',
    answer: 'vienes',
    category: 'to-come',
    acceptableAnswers: [],
  },
  {
    question: 'ő jön',
    description: 'jelen',
    answer: 'viene',
    category: 'to-come',
    acceptableAnswers: [],
  },
  {
    question: 'mi jövünk',
    description: 'jelen',
    answer: 'venimos',
    category: 'to-come',
    acceptableAnswers: [],
  },
  {
    question: 'ti jöttök',
    description: 'jelen',
    answer: 'venís',
    category: 'to-come',
    acceptableAnswers: [],
  },
  {
    question: 'ők jönnek',
    description: 'jelen',
    answer: 'vienen',
    category: 'to-come',
    acceptableAnswers: [],
  },
  {
    question: 'én jöttem',
    description: 'egyszerű múlt',
    answer: 'vine',
    category: 'to-come',
    acceptableAnswers: [],
  },
  {
    question: 'te jöttél',
    description: 'egyszerű múlt',
    answer: 'viniste',
    category: 'to-come',
    acceptableAnswers: [],
  },
  {
    question: 'ő jött',
    description: 'egyszerű múlt',
    answer: 'vino',
    category: 'to-come',
    acceptableAnswers: [],
  },
  {
    question: 'mi jöttünk',
    description: 'egyszerű múlt',
    answer: 'vinimos',
    category: 'to-come',
    acceptableAnswers: [],
  },
  {
    question: 'ti jöttetek',
    description: 'egyszerű múlt',
    answer: 'vinisteis',
    category: 'to-come',
    acceptableAnswers: [],
  },
  {
    question: 'ők jöttek',
    description: 'egyszerű múlt',
    answer: 'vinieron',
    category: 'to-come',
    acceptableAnswers: [],
  },

  /**
   * TO MAKE
   */
  {
    question: 'csinálni',
    description: '',
    answer: 'hacer',
    category: 'to-make',
    acceptableAnswers: [],
  },
  {
    question: 'én csinálom',
    description: 'jelen',
    answer: 'hago',
    category: 'to-make',
    acceptableAnswers: [],
  },
  {
    question: 'te csinálod',
    description: 'jelen',
    answer: 'haces',
    category: 'to-make',
    acceptableAnswers: [],
  },
  {
    question: 'ő csinálja',
    description: 'jelen',
    answer: 'hace',
    category: 'to-make',
    acceptableAnswers: [],
  },
  {
    question: 'mi csináljuk',
    description: 'jelen',
    answer: 'hacemos',
    category: 'to-make',
    acceptableAnswers: [],
  },
  {
    question: 'ti csináljátok',
    description: 'jelen',
    answer: 'hacéis',
    category: 'to-make',
    acceptableAnswers: [],
  },
  {
    question: 'ők csinálják',
    description: 'jelen',
    answer: 'hacen',
    category: 'to-make',
    acceptableAnswers: [],
  },
  {
    question: 'én csináltam',
    description: 'egyszerű múlt',
    answer: 'hice',
    category: 'to-make',
    acceptableAnswers: [],
  },
  {
    question: 'te csináltad',
    description: 'egyszerű múlt',
    answer: 'hiciste',
    category: 'to-make',
    acceptableAnswers: [],
  },
  {
    question: 'ő csinálta',
    description: 'egyszerű múlt',
    answer: 'hizo',
    category: 'to-make',
    acceptableAnswers: [],
  },
  {
    question: 'mi csináltuk',
    description: 'egyszerű múlt',
    answer: 'hicimos',
    category: 'to-make',
    acceptableAnswers: [],
  },
  {
    question: 'ti csináltátok',
    description: 'egyszerű múlt',
    answer: 'hicisteis',
    category: 'to-make',
    acceptableAnswers: [],
  },
  {
    question: 'ők csinálták',
    description: 'egyszerű múlt',
    answer: 'hicieron',
    category: 'to-make',
    acceptableAnswers: [],
  },

  /**
   * SAY - DECIR
   */
  {
    question: 'mondani',
    description: '',
    answer: 'decir',
    category: 'to-say',
    acceptableAnswers: [],
  },
  {
    question: 'én mondom',
    description: 'jelen',
    answer: 'digo',
    category: 'to-say',
    acceptableAnswers: [],
  },
  {
    question: 'te mondod',
    description: 'jelen',
    answer: 'dices',
    category: 'to-say',
    acceptableAnswers: [],
  },
  {
    question: 'ő mondja',
    description: 'jelen',
    answer: 'dice',
    category: 'to-say',
    acceptableAnswers: [],
  },
  {
    question: 'mi mondjuk',
    description: 'jelen',
    answer: 'decimos',
    category: 'to-say',
    acceptableAnswers: [],
  },
  {
    question: 'ti mondjátok',
    description: 'jelen',
    answer: 'decís',
    category: 'to-say',
    acceptableAnswers: [],
  },
  {
    question: 'ők mondják',
    description: 'jelen',
    answer: 'dicen',
    category: 'to-say',
    acceptableAnswers: [],
  },
  {
    question: 'én mondtam',
    description: 'egyszerű múlt',
    answer: 'dije',
    category: 'to-say',
    acceptableAnswers: [],
  },
  {
    question: 'te mondtad',
    description: 'egyszerű múlt',
    answer: 'dijiste',
    category: 'to-say',
    acceptableAnswers: [],
  },
  {
    question: 'ő mondta',
    description: 'egyszerű múlt',
    answer: 'dijo',
    category: 'to-say',
    acceptableAnswers: [],
  },
  {
    question: 'mi mondtuk',
    description: 'egyszerű múlt',
    answer: 'dijimos',
    category: 'to-say',
    acceptableAnswers: [],
  },
  {
    question: 'ti mondtátok',
    description: 'egyszerű múlt',
    answer: 'dijisteis',
    category: 'to-say',
    acceptableAnswers: [],
  },
  {
    question: 'ők mondták',
    description: 'egyszerű múlt',
    answer: 'dijeron',
    category: 'to-say',
    acceptableAnswers: [],
  },

  /**
   * UNCONJUGATED VERBS
   */
  {
    question: 'menteni / spórolni',
    description: '',
    answer: 'ahorar',
    category: 'unconjugated',
    acceptableAnswers: [],
  },
  {
    question: 'hiányt szenvedni // hiányozni',
    description: '',
    answer: 'faltar',
    category: 'unconjugated',
    acceptableAnswers: [],
  },
  {
    question: 'fordít',
    description: '',
    answer: 'traducir',
    category: 'unconjugated',
    acceptableAnswers: [],
  },
  {
    question: 'megengedni vmit // elmenni',
    description: '',
    answer: 'dejar',
    category: 'unconjugated',
    acceptableAnswers: [],
  },
  {
    question: 'Csomó üzenetet küldeni',
    description: 'rátapadni valakire',
    answer: 'Atosigar',
    category: 'unconjugated',
    acceptableAnswers: [],
  },
  {
    question: 'hiányozni',
    description: '',
    answer: 'extrañar',
    category: 'unconjugated',
    acceptableAnswers: [],
  },
  {
    question: 'kérdezni',
    description: '',
    answer: 'preguntar',
    category: 'unconjugated',
    acceptableAnswers: [],
  },
  {
    question: 'megmutat',
    description: '',
    answer: 'mostrar',
    category: 'unconjugated',
    acceptableAnswers: [],
  },
  {
    question: 'meglátogat',
    description: '',
    answer: 'visitar',
    category: 'unconjugated',
    acceptableAnswers: [],
  },
  {
    question: 'ölelni',
    description: '',
    answer: 'abrasar',
    category: 'unconjugated',
    acceptableAnswers: [],
  },
  {
    question: 'csekkol',
    description: '',
    answer: 'verificar',
    category: 'unconjugated',
    acceptableAnswers: [],
  },
  {
    question: 'vicsorog',
    description: '',
    answer: 'gruñir',
    category: 'unconjugated',
    acceptableAnswers: [],
  },
  {
    question: 'írni',
    description: '',
    answer: 'escribir',
    category: 'unconjugated',
    acceptableAnswers: [],
  },
  {
    question: 'tétet tenni',
    description: '',
    answer: 'apostar',
    category: 'unconjugated',
    acceptableAnswers: [],
  },
  {
    question: 'javítani',
    description: '',
    answer: 'arreglar',
    category: 'unconjugated',
    acceptableAnswers: [],
  },

  /**
   * UNCONJUGATED VERBS II
   */
  {
    question: 'tanítani',
    description: '',
    answer: 'Enseñar',
    category: 'unconjugated-ii',
    acceptableAnswers: [],
  },
  {
    question: 'küld',
    description: 'informális',
    answer: 'mandar',
    category: 'unconjugated-ii',
    acceptableAnswers: [],
  },
  {
    question: 'kinyitni',
    description: '',
    answer: 'abrir',
    category: 'unconjugated-ii',
    acceptableAnswers: [],
  },
  {
    question: 'befejezni',
    description: '',
    answer: 'acabar',
    category: 'unconjugated-ii',
    acceptableAnswers: [],
  },
  {
    question: 'elfogadni',
    description: '',
    answer: 'aceptar',
    category: 'unconjugated-ii',
    acceptableAnswers: [],
  },
  {
    question: 'elérni vmit',
    description: '(pl.: buszt)',
    answer: 'alcanzar',
    category: 'unconjugated-ii',
    acceptableAnswers: [],
  },
  {
    question: 'megjelenni',
    description: '',
    answer: 'aparecer',
    category: 'unconjugated-ii',
    acceptableAnswers: [],
  },
  {
    question: 'segíteni',
    description: '',
    answer: 'ayudar',
    category: 'unconjugated-ii',
    acceptableAnswers: [],
  },
  {
    question: 'esik/zuhan',
    description: '',
    answer: 'caer',
    category: 'unconjugated-ii',
    acceptableAnswers: [],
  },
  {
    question: 'kezdeni vmit',
    description: '',
    answer: 'comenzar',
    category: 'unconjugated-ii',
    acceptableAnswers: [],
  },
  {
    question: 'megérteni',
    description: 'koncepciót',
    answer: 'comprender',
    category: 'unconjugated-ii',
    acceptableAnswers: [],
  },
  {
    question: 'elgondolkodik / figyelembe vesz',
    description: '',
    answer: 'considerar',
    category: 'unconjugated-ii',
    acceptableAnswers: [],
  },
  {
    question: 'megszámol',
    description: '',
    answer: 'contar',
    category: 'unconjugated-ii',
    acceptableAnswers: [],
  },
  {
    question: 'átváltozik',
    description: '',
    answer: 'convertir',
    category: 'unconjugated-ii',
    acceptableAnswers: [],
  },
  {
    question: 'készít / kreál',
    description: '',
    answer: 'crear',
    category: 'unconjugated-ii',
    acceptableAnswers: [],
  },

  /**
   * UNCONJUGATED VERBS III
   */
  {
    question: 'hiszem / gondolom',
    description: '',
    answer: 'creer',
    category: 'unconjugated-iii',
    acceptableAnswers: [],
  },
  {
    question: 'elér valamit / csinálni vmit',
    description: 'kötelesség',
    answer: 'cumplir',
    category: 'unconjugated-iii',
    acceptableAnswers: [],
  },
  {
    question: 'tartozni',
    description: '',
    answer: 'deber',
    category: 'unconjugated-iii',
    acceptableAnswers: [],
  },
  {
    question: 'felfedez',
    description: '',
    answer: 'descubrir',
    category: 'unconjugated-iii',
    acceptableAnswers: [],
  },
  {
    question: 'irányít / menedzsel',
    description: '',
    answer: 'dirigir',
    category: 'unconjugated-iii',
    acceptableAnswers: [],
  },
  {
    question: 'elkezd',
    description: '',
    answer: 'empezar',
    category: 'unconjugated-iii',
    acceptableAnswers: [],
  },
  {
    question: 'bemegy / belép',
    description: '',
    answer: 'entrar',
    category: 'unconjugated-iii',
    acceptableAnswers: [],
  },
  {
    question: 'várni vagy remélni',
    description: '',
    answer: 'esperar',
    category: 'unconjugated-iii',
    acceptableAnswers: [],
  },
  {
    question: 'van / létezik',
    description: '',
    answer: 'existir',
    category: 'unconjugated-iii',
    acceptableAnswers: [],
  },
  {
    question: 'elmagyaráz',
    description: '',
    answer: 'explicar',
    category: 'unconjugated-iii',
    acceptableAnswers: [],
  },

  /**
   * IV. UNCONJUGATED VERBS - IV
   */
  {
    question: 'megalapítani / kialakítani',
    description: '',
    answer: 'formar',
    category: 'unconjugated-iv',
    acceptableAnswers: [],
  },
  {
    question: 'megszerezni / elnyerni',
    description: '',
    answer: 'ganar',
    category: 'unconjugated-iv',
    acceptableAnswers: [],
  },
  {
    question: 'tetszeni',
    description: '',
    answer: 'gustar',
    category: 'unconjugated-iv',
    acceptableAnswers: [],
  },
  {
    question: 'Van vmi vhol',
    description: 'Angol: there is',
    answer: 'haber',
    category: 'unconjugated-iv',
    acceptableAnswers: [],
  },
  {
    question: 'beszélni',
    description: '',
    answer: 'hablar',
    category: 'unconjugated-iv',
    acceptableAnswers: [],
  },
  {
    question: 'csinálni',
    description: '',
    answer: 'hacer',
    category: 'unconjugated-iv',
    acceptableAnswers: [],
  },
  {
    question: 'megpróbálni',
    description: '',
    answer: 'intentar',
    category: 'unconjugated-iv',
    acceptableAnswers: [],
  },
  {
    question: 'menni',
    description: '',
    answer: 'ir',
    category: 'unconjugated-iv',
    acceptableAnswers: [],
  },
  {
    question: 'játszani',
    description: '',
    answer: 'jugar',
    category: 'unconjugated-iv',
    acceptableAnswers: [],
  },
  {
    question: 'felvesz / felemel',
    description: '',
    answer: 'levantar',
    category: 'unconjugated-iv',
    acceptableAnswers: [],
  },
  {
    question: 'hívni',
    description: '',
    answer: 'llamar',
    category: 'unconjugated-iv',
    acceptableAnswers: [],
  },
  {
    question: 'megérkezni',
    description: '',
    answer: 'llegar',
    category: 'unconjugated-iv',
    acceptableAnswers: [],
  },
  {
    question: 'cipel / tart / visel',
    description: '',
    answer: 'llevar',
    category: 'unconjugated-iv',
    acceptableAnswers: [],
  },
  {
    question: 'elérni valamit',
    description: 'pl.: amire vágytam',
    answer: 'lograr',
    category: 'unconjugated-iv',
    acceptableAnswers: [],
  },
  {
    question: 'rendben tart',
    description: '',
    answer: 'mantener',
    category: 'unconjugated-iv',
    acceptableAnswers: [],
  },

  /**
   * UNCONJUGATED WORDS - V
   */
  {
    question: 'megnézni',
    description: '',
    answer: 'mirar',
    category: 'unconjugated-v',
    acceptableAnswers: [],
  },
  {
    question: 'meghalni',
    description: '',
    answer: 'morir',
    category: 'unconjugated-v',
    acceptableAnswers: [],
  },
  {
    question: 'Megszületni',
    description: '',
    answer: 'nacer',
    category: 'unconjugated-v',
    acceptableAnswers: [],
  },
  {
    question: 'igényelni vmit',
    description: '',
    answer: 'necesitar',
    category: 'unconjugated-v',
    acceptableAnswers: [],
  },
  {
    question: 'megtörténik, bekövetkezik',
    description: '',
    answer: 'ocurrir',
    category: 'unconjugated-v',
    acceptableAnswers: [],
  },
  {
    question: 'ajánlani',
    description: '',
    answer: 'ofrecer',
    category: 'unconjugated-v',
    acceptableAnswers: [],
  },
  {
    question: 'hallani',
    description: '',
    answer: 'oír',
    category: 'unconjugated-v',
    acceptableAnswers: [],
  },
  {
    question: 'fizetni',
    description: '',
    answer: 'pagar',
    category: 'unconjugated-v',
    acceptableAnswers: [],
  },
  {
    question: 'megállni',
    description: '',
    answer: 'parar',
    category: 'unconjugated-v',
    acceptableAnswers: [],
  },
  {
    question: 'Kinézni / tűnni (valamilyennek)',
    description: '',
    answer: 'parecer',
    category: 'unconjugated-v',
    acceptableAnswers: [],
  },
  {
    question: 'eltávozik / elindul',
    description: '',
    answer: 'partir',
    category: 'unconjugated-v',
    acceptableAnswers: [],
  },
  {
    question: 'történik / folyik / elmúlik',
    description: '',
    answer: 'pasar',
    category: 'unconjugated-v',
    acceptableAnswers: [],
  },
  {
    question: 'kér / kölcsönkér',
    description: '',
    answer: 'pedir',
    category: 'unconjugated-v',
    acceptableAnswers: [],
  },
  {
    question: 'Engedélyezni valamit',
    description: '',
    answer: 'permitir',
    category: 'unconjugated-v',
    acceptableAnswers: [],
  },
  {
    question: 'bemutat / kiállít',
    description: '',
    answer: 'presentar',
    category: 'unconjugated-v',
    acceptableAnswers: [],
  },

  /**
   * UNCONJUGATED VERBS VI
   */
  {
    question: 'termel / előállít',
    description: '',
    answer: 'producir',
    category: 'unconjugated-vi',
    acceptableAnswers: [],
  },
  {
    question: 'Maradni // megtartani',
    description: '',
    answer: 'quedar',
    category: 'unconjugated-vi',
    acceptableAnswers: [],
  },
  {
    question: 'Elérni valamit / megvalósítani',
    description: '',
    answer: 'realizar',
    category: 'unconjugated-vi',
    acceptableAnswers: [],
  },
  {
    question: 'kapni / fogadni',
    description: '',
    answer: 'recibir',
    category: 'unconjugated-vi',
    acceptableAnswers: [],
  },
  {
    question: 'Felismerni',
    description: '',
    answer: 'reconocer',
    category: 'unconjugated-vi',
    acceptableAnswers: [],
  },
  {
    question: 'emlékezni',
    description: '',
    answer: 'recordar',
    category: 'unconjugated-vi',
    acceptableAnswers: [],
  },
  {
    question: 'Végződni / Valamilyen eredménnyel záródni',
    description: '',
    answer: 'resultar',
    category: 'unconjugated-vi',
    acceptableAnswers: [],
  },
  {
    question: 'Elővesz',
    description: '',
    answer: 'sacar',
    category: 'unconjugated-vi',
    acceptableAnswers: [],
  },
  {
    question: 'követ',
    description: '',
    answer: 'seguir',
    category: 'unconjugated-vi',
    acceptableAnswers: [],
  },
  {
    question: 'érezni',
    description: '',
    answer: 'sentir',
    category: 'unconjugated-vi',
    acceptableAnswers: [],
  },
  {
    question: 'kiszolgál / felszolgál',
    description: '',
    answer: 'servir',
    category: 'unconjugated-vi',
    acceptableAnswers: [],
  },
  {
    question: 'feltételez / kitalál',
    description: '',
    answer: 'suponer',
    category: 'unconjugated-vi',
    acceptableAnswers: [],
  },
  {
    question: 'hozni',
    description: '',
    answer: 'traer',
    category: 'unconjugated-vi',
    acceptableAnswers: [],
  },
  {
    question: 'bánik vkivel',
    description: '',
    answer: 'tratar',
    category: 'unconjugated-vi',
    acceptableAnswers: [],
  },

  /**
   * UNCONJUGATED WORDS VII
   */
  {
    question: 'használni vmit vmire',
    description: '',
    answer: 'utilizar',
    category: 'unconjugated-vii',
    acceptableAnswers: [],
  },
  {
    question: 'visszajönni',
    description: '',
    answer: 'volver',
    category: 'unconjugated-vii',
    acceptableAnswers: [],
  },
  {
    question: 'Ég',
    description: '',
    answer: 'arder',
    category: 'unconjugated-vii',
    acceptableAnswers: [],
  },
  {
    question: 'inni / vesz / tart / kap',
    description: 'take',
    answer: 'tomar',
    category: 'unconjugated-vii',
    acceptableAnswers: [],
  },
  {
    question: 'blokkolni',
    description: '',
    answer: 'bloquear',
    category: 'unconjugated-vii',
    acceptableAnswers: [],
  },
  {
    question: 'csatlakozni',
    description: '',
    answer: 'unir',
    category: 'unconjugated-vii',
    acceptableAnswers: [],
  },
  {
    question: 'megerősít',
    description: '',
    answer: 'corroborar',
    category: 'unconjugated-vii',
    acceptableAnswers: [],
  },
  {
    question: 'növel / nagyít',
    description: '',
    answer: 'ampliar',
    category: 'unconjugated-vii',
    acceptableAnswers: [],
  },
  {
    question: 'elkerül, megkerül',
    description: '',
    answer: 'sortear',
    category: 'unconjugated-vii',
    acceptableAnswers: [],
  },
  {
    question: 'berendezni (bútorokkal)',
    description: '',
    answer: 'amueblar',
    category: 'unconjugated-vii',
    acceptableAnswers: [],
  },
  {
    question: 'megölni vmit',
    description: '',
    answer: 'matar',
    category: 'unconjugated-vii',
    acceptableAnswers: [],
  },
  {
    question: 'tartani',
    description: '',
    answer: 'aguantar',
    category: 'unconjugated-vii',
    acceptableAnswers: [],
  },
  {
    question: 'valakihez/vmihez tartozni',
    description: '',
    answer: 'corresponder',
    category: 'unconjugated-vii',
    acceptableAnswers: [],
  },
  {
    question: 'történetet mesélni',
    description: '',
    answer: 'contar',
    category: 'unconjugated-vii',
    acceptableAnswers: [],
  },
  {
    question: 'gondolni',
    description: '',
    answer: 'pensar',
    category: 'unconjugated-vii',
    acceptableAnswers: [],
  },
  {
    question: 'észrevenni / feltűnik',
    description: '',
    answer: 'notar',
    category: 'unconjugated-vii',
    acceptableAnswers: [],
  },

  /**
   * UNCONJUGATED-VIII
   */
  {
    question: 'tart (ideig)',
    description: '',
    answer: 'tardar',
    category: 'unconjugated-viii',
    acceptableAnswers: [],
  },
  {
    question: 'házasodni',
    description: '',
    answer: 'casarte',
    category: 'unconjugated-viii',
    acceptableAnswers: [],
  },
  {
    question: 'sikert elérni',
    description: '',
    answer: 'triunfar',
    category: 'unconjugated-viii',
    acceptableAnswers: [],
  },
  {
    question: 'kitalálni',
    description: '',
    answer: 'adivinar',
    category: 'unconjugated-viii',
    acceptableAnswers: [],
  },
  {
    question: 'megütni',
    description: '',
    answer: 'golpear',
    category: 'unconjugated-viii',
    acceptableAnswers: [],
  },
  {
    question: 'ösztönözni // felvidítani',
    description: '',
    answer: 'animar',
    category: 'unconjugated-viii',
    acceptableAnswers: [],
  },
  {
    question: 'dobni / húzni',
    description: '',
    answer: 'tirar',
    category: 'unconjugated-viii',
    acceptableAnswers: [],
  },
  {
    question: 'megváltoztat',
    description: '',
    answer: 'alterar',
    category: 'unconjugated-viii',
    acceptableAnswers: [],
  },
  {
    question: 'megtalálni',
    description: '',
    answer: 'hallar',
    category: 'unconjugated-viii',
    acceptableAnswers: [],
  },
  {
    question: 'helyezni // helyezkedni',
    description: '',
    answer: 'situar',
    category: 'unconjugated-viii',
    acceptableAnswers: [],
  },
  {
    question: 'megijeszt',
    description: '',
    answer: 'asustar',
    category: 'unconjugated-viii',
    acceptableAnswers: [],
  },
  {
    question: 'kihúzni a dugót',
    description: '',
    answer: 'descorchar',
    category: 'unconjugated-viii',
    acceptableAnswers: [],
  },
  {
    question: 'megigérni',
    description: '',
    answer: 'prometer',
    category: 'unconjugated-viii',
    acceptableAnswers: [],
  },
  {
    question: 'tósztot mond',
    description: '',
    answer: 'brindar',
    category: 'unconjugated-viii',
    acceptableAnswers: [],
  },
  {
    question: 'meglep',
    description: '',
    answer: 'sorprender',
    category: 'unconjugated-viii',
    acceptableAnswers: [],
  },
  {
    question: 'Ajánlani',
    description: '',
    answer: 'sugerir',
    category: 'unconjugated-viii',
    acceptableAnswers: [],
  },
  {
    question: 'borotválkozni',
    description: '',
    answer: 'afeitar',
    category: 'unconjugated-viii',
    acceptableAnswers: [],
  },
  {
    question: 'tönkretesz',
    description: '',
    answer: 'estropear',
    category: 'unconjugated-viii',
    acceptableAnswers: [],
  },

  /**
   * UNCONJUGATED IX
   */
  {
    question: 'elköteleződni',
    description: '',
    answer: 'comprometer',
    category: 'unconjugated-ix',
    acceptableAnswers: [],
  },
  {
    question: 'büntetésben lenni',
    description: '',
    answer: 'estar castigando',
    category: 'unconjugated-ix',
    acceptableAnswers: [],
  },
  {
    question: 'vmi alapján // ered',
    description: '',
    answer: 'basar',
    category: 'unconjugated-ix',
    acceptableAnswers: [],
  },
  {
    question: 'megfélemlít',
    description: '',
    answer: 'acobardar',
    category: 'unconjugated-ix',
    acceptableAnswers: [],
  },
  {
    question: 'feltenni',
    description: 'to put on',
    answer: 'ponerse',
    category: 'unconjugated-ix',
    acceptableAnswers: [],
  },
  {
    question: 'esküdni',
    description: '',
    answer: 'jurar',
    category: 'unconjugated-ix',
    acceptableAnswers: [],
  },
  {
    question: 'lefektetni',
    description: '',
    answer: 'acostar',
    category: 'unconjugated-ix',
    acceptableAnswers: [],
  },
  {
    question: 'feltölt / megemel / beszáll',
    description: '',
    answer: 'subir',
    category: 'unconjugated-ix',
    acceptableAnswers: [],
  },
  {
    question: 'visszaad // viszonoz',
    description: '',
    answer: 'devolver',
    category: 'unconjugated-ix',
    acceptableAnswers: [],
  },
  {
    question: 'figyelmeztetni',
    description: '',
    answer: 'advertir',
    category: 'unconjugated-ix',
    acceptableAnswers: [],
  },
  {
    question: 'elkövet',
    description: '',
    answer: 'cometer',
    category: 'unconjugated-ix',
    acceptableAnswers: [],
  },
  {
    question: 'gratulál',
    description: '',
    answer: 'felicitar',
    category: 'unconjugated-ix',
    acceptableAnswers: [],
  },
  {
    question: 'belefoglalni',
    description: 'angol: include',
    answer: 'incluir',
    category: 'unconjugated-ix',
    acceptableAnswers: [],
  },
  {
    question: 'megállítani',
    description: '',
    answer: 'detener',
    category: 'unconjugated-ix',
    acceptableAnswers: [],
  },
  {
    question: 'elajándékozni',
    description: '',
    answer: 'regalar',
    category: 'unconjugated-ix',
    acceptableAnswers: [],
  },
  {
    question: 'keresztez // keresztbe megy',
    description: '',
    answer: 'cruzar',
    category: 'unconjugated-ix',
    acceptableAnswers: [],
  },
  {
    question: 'bátani',
    description: '',
    answer: 'doler',
    category: 'unconjugated-ix',
    acceptableAnswers: [],
  },
  {
    question: 'kihagy, elkerül',
    description: '',
    answer: 'evitar',
    category: 'unconjugated-ix',
    acceptableAnswers: [],
  },

  /**
   * UNCONJUGATED VERBS X
   */
  {
    question: 'igaza van vkinek',
    description: 'vagy helyesen tippelni',
    answer: 'acertar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'kacsint, pislog',
    description: 'wink',
    answer: 'guiñarte',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'visszaesés',
    description: 'relapse',
    answer: 'recaer',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'sikoly',
    description: '',
    answer: 'chillar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'ugrani',
    description: '',
    answer: 'saltar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'flörtölni',
    description: '',
    answer: 'coquetear',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'lejárni // esedékessé válik // befejez // legyőz',
    description: '',
    answer: 'vencer',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'mesterévé válni valaminek',
    description: '',
    answer: 'dominar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'megvilágít',
    description: '',
    answer: 'alumbrar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'megemel',
    description: '',
    answer: 'elevar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'símogat / cirógat',
    description: '',
    answer: 'acariciar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'törölni',
    description: '',
    answer: 'borrar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'szállít',
    description: '',
    answer: 'entregar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'visszateker',
    description: 'angol: rewind',
    answer: 'rebobinar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'viselkedik',
    description: '',
    answer: 'comportar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'elhagy / elsétál',
    description: '',
    answer: 'alejarse',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'átveszi az irányítást',
    description: '',
    answer: 'apoderar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'elutasít / visszautasít',
    description: 'angol: decline',
    answer: 'negarse',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'importál / foglalkozik vmivel / törődik vmivel',
    description: '',
    answer: 'importar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'szórakoztat',
    description: '',
    answer: 'divertir',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'ismétel',
    description: '',
    answer: 'repetir',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'kikapcsolni',
    description: '',
    answer: 'apagar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'vágyni valamire',
    description: '',
    answer: 'desear',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'megfogni',
    description: '',
    answer: 'pillar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'nevetni',
    description: '',
    answer: 'reír',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'kötelez',
    description: '',
    answer: 'obligar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'félbeszakítani vmit',
    description: 'pl.: beszélgetés',
    answer: 'interrumpir',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'felöltözni',
    description: '',
    answer: 'vestirse',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'megment',
    description: '',
    answer: 'salvar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'megbíz',
    description: '',
    answer: 'encargar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'gondoskodni',
    description: '',
    answer: 'encargarse',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'kockára tesz / kockáztat',
    description: '',
    answer: 'arriesgar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'lereszt (pl. gumi)',
    description: 'angol: deflated',
    answer: 'desinflar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'tönkretesz / elszakad / eltörik',
    description: '',
    answer: 'romper',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'megszabadulni vmitől/kitől',
    description: '',
    answer: 'deshicar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'izgatottá tenni',
    description: '',
    answer: 'emocionar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'elfedni',
    description: '',
    answer: 'tapar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'válaszol',
    description: '',
    answer: 'contestar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'reagál / válaszol',
    description: '',
    answer: 'responder',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'mérni',
    description: '',
    answer: 'medir',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'elcsábít',
    description: '',
    answer: 'seducir',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'visszatér / folytat',
    description: '',
    answer: 'retomar',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },
  {
    question: 'kérdezni',
    description: '',
    answer: 'pedírselo',
    category: 'unconjugated-x',
    acceptableAnswers: [],
  },

  /**
   * BASICS
   */
  {
    question: 'Bocsánat',
    description: 'Kis dologra',
    answer: 'perdón',
    category: 'basics',
    acceptableAnswers: [],
  },
  {
    question: 'Bocsánat',
    description: 'Nagy dologra',
    answer: 'lo siento',
    category: 'basics',
    acceptableAnswers: [],
  },
  {
    question: 'Bocsánat',
    description: 'Valakit félbeszakít / ha útban állnak',
    answer: 'con permiso',
    category: 'basics',
    acceptableAnswers: [],
  },
  {
    question: 'Bocsánat',
    description: 'Eladót kérdezni',
    answer: 'disculpe',
    category: 'basics',
    acceptableAnswers: [],
  },
  {
    question: 'Viszont látásra!',
    description: '',
    answer: 'hasta luego',
    category: 'basics',
    acceptableAnswers: [],
  },
  {
    question: 'Jó pihenést',
    description: '',
    answer: 'descansa',
    category: 'basics',
    acceptableAnswers: [],
  },

  /**
   * NUMBERS -> 19
   */
  {
    question: 'Egy',
    description: 'Szám',
    answer: 'uno',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'Kettő',
    description: '',
    answer: 'dos',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'három',
    description: '',
    answer: 'tres',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'négy',
    description: '',
    answer: 'cuatro',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'öt',
    description: '',
    answer: 'cinco',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'hat',
    description: '',
    answer: 'seis',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'hét',
    description: '',
    answer: 'siete',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'nyolc',
    description: '',
    answer: 'ocho',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'kilenc',
    description: '',
    answer: 'nueve',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'tíz',
    description: '',
    answer: 'diez',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'tizenegy',
    description: '',
    answer: 'once',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'tizenkettő',
    description: '',
    answer: 'doce',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'tizenhárom',
    description: '',
    answer: 'trece',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'tizennégy',
    description: '',
    answer: 'catorce',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'tizenöt',
    description: '',
    answer: 'quince',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'tizenhat',
    description: '',
    answer: 'dieciséis',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'tizenhét',
    description: '',
    answer: 'diecisiete',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'tizennyolc',
    description: '',
    answer: 'dieciocho',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'tizenkilenc',
    description: '',
    answer: 'diecinueve',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'húsz',
    description: '',
    answer: 'veinte',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'huszon-',
    description: '',
    answer: 'veinti',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'harminc',
    description: '',
    answer: 'treinta',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'negyven',
    description: '',
    answer: 'cuarenta',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'ötven',
    description: '',
    answer: 'cincuenta',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'hatvan',
    description: '',
    answer: 'sesenta',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'hetven',
    description: '',
    answer: 'setenta',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'nyolcvan',
    description: '',
    answer: 'ochenta',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'kilencven',
    description: '',
    answer: 'noventa',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },
  {
    question: 'száz',
    description: '',
    answer: 'cien',
    category: 'Numbers 1-100',
    acceptableAnswers: [],
  },

  /**
   * HOUSEHOLD
   */
  {
    question: 'porszívó',
    description: '',
    answer: 'aspiradora',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'piszoár',
    description: '',
    answer: 'urinario',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'szekrény, polcok',
    description: '',
    answer: 'estantería',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'váza',
    description: '',
    answer: 'jarrón',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'kabáttartó',
    description: '',
    answer: 'perchero',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'szemetes zsák',
    description: '',
    answer: 'bolsa de basura',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'nappali',
    description: '',
    answer: 'sala',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'mosogép',
    description: '',
    answer: 'lavadora',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'szárítógép',
    description: '',
    answer: 'secadora',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'hosszabbító',
    description: '',
    answer: 'extensión eléctrica',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'fúró',
    description: '',
    answer: 'taladro',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'csavarhúzó',
    description: '',
    answer: 'destornillador',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'kalapács',
    description: '',
    answer: 'martillo',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'szög',
    description: 'amit a kalapács bever',
    answer: 'clavos',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'csavar',
    description: '',
    answer: 'tornilla',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'matrac',
    description: '',
    answer: 'colchón',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'szúnyogháló',
    description: '',
    answer: 'miriñaque',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'kés',
    description: '',
    answer: 'nabaja',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'virágcserép',
    description: '',
    answer: 'maceta',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'Kötél',
    description: '',
    answer: 'cuerda',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'párkány',
    description: '',
    answer: 'cornisa',
    category: 'household',
    acceptableAnswers: [],
  },
  {
    question: 'padló',
    description: '',
    answer: 'suelo',
    category: 'household',
    acceptableAnswers: [],
  },

  /**
   * HEALTH
   */
  {
    question: 'fogkrém',
    description: '',
    answer: 'pasta de dientes',
    category: 'health',
    acceptableAnswers: [],
  },
  {
    question: 'hasmenés',
    description: '',
    answer: 'diarrea',
    category: 'health',
    acceptableAnswers: [],
  },
  {
    question: 'beteg',
    description: '',
    answer: 'enfermo',
    category: 'health',
    acceptableAnswers: [],
  },
  {
    question: 'nyirok rendszer',
    description: '',
    answer: 'gangleos',
    category: 'health',
    acceptableAnswers: [],
  },
  {
    question: 'cipő betét',
    description: '',
    answer: 'plantilla',
    category: 'health',
    acceptableAnswers: [],
  },
  {
    question: 'lúdtalp betét',
    description: '',
    answer: 'plantilla de pie plano',
    category: 'health',
    acceptableAnswers: [],
  },
  {
    question: 'elájul',
    description: '',
    answer: 'patatús',
    category: 'health',
    acceptableAnswers: [],
  },
  {
    question: 'Túlélek',
    description: '',
    answer: 'Sobrevivo',
    category: 'health',
    acceptableAnswers: [],
  },
  {
    question: 'dehidratált // másnapos',
    description: '',
    answer: 'cruda',
    category: 'health',
    acceptableAnswers: [],
  },
  {
    question: 'megfázás // orrfojás',
    description: '',
    answer: 'rinitis',
    category: 'health',
    acceptableAnswers: [],
  },

  /**
   * EXPRESSIONS
   */
  {
    question: 'kaphatnék?',
    description: '',
    answer: 'puedo conseguir',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'Muszáj vmit csinálni',
    description: '',
    answer: 'deber + verbo',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'valakinek igaza van',
    description: '',
    answer: 'tener razón',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'I\'m glad for',
    description: '',
    answer: 'me alegro por',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'nem illik össze xy-val',
    description: '',
    answer: 'No combina con',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'Damn!',
    description: '',
    answer: 'Maldición',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'milyen sármos',
    description: '',
    answer: 'que guapo',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'büdös a hónaalja',
    description: '',
    answer: 'chilla la ardilla',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'gyakori?',
    description: '',
    answer: 'Es común',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'Finomnak néz ki',
    description: '',
    answer: 'se ve apetitoso',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'Nem illik hozzám',
    description: '',
    answer: 'no se llevan conmigo',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'fent lent középen és bent',
    description: 'ivós vicc - egészségedre helyett',
    answer: 'arriba abajo al centro pa dentro',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'Nagyon be fogok rúgni',
    description: 'Mint a kutya tojása',
    answer: 'me voy a poner como huevo de perro',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'Olyan leszek mint',
    description: '',
    answer: 'me voy a poner como',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'Libabőrös',
    description: '',
    answer: 'Piel de gallina',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'Viccelek',
    description: '',
    answer: 'es bromo',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'szerencsére',
    description: '',
    answer: 'menos mal',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'Elengedek mindent',
    description: '',
    answer: 'lo dejo ir todo',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'milyen kínos',
    description: '',
    answer: 'que oso',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'kellene vmit csinálni',
    description: 'Angol: I should',
    answer: 'debería',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'Épp csak',
    description: 'Angol: I have just',
    answer: 'acabo de',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'Még soha (nem csináltam valamit)',
    description: 'Angol: I have never // végére participio forma kell',
    answer: 'nunca lo he',
    category: 'expressions',
    acceptableAnswers: [],
  },
  {
    question: 'természetesen',
    description: 'nagyon formális',
    answer: 'Por supuesto',
    category: 'expressions',
    acceptableAnswers: [],
  },

  /**
   * EXPRESSIONS II
   */
  {
    question: 'elcseszni',
    description: '',
    answer: 'meter la pata',
    category: 'expressions-ii',
    acceptableAnswers: [],
  },
  {
    question: 'Nem annyira // nem túl',
    description: '',
    answer: 'no tanto',
    category: 'expressions-ii',
    acceptableAnswers: [],
  },
  {
    question: 'élvezni // have fun',
    description: '',
    answer: 'pasarla bien',
    category: 'expressions-ii',
    acceptableAnswers: [],
  },
  {
    question: 'meg csak ne is xy-olj',
    description: '',
    answer: 'ni siquiera',
    category: 'expressions-ii',
    acceptableAnswers: [],
  },
  {
    question: 'de tényleg',
    description: '',
    answer: 'de veras',
    category: 'expressions-ii',
    acceptableAnswers: [],
  },
  {
    question: 'éppen hogy // csak egy kicsit',
    description: 'angol: Barely',
    answer: 'apenas',
    category: 'expressions-ii',
    acceptableAnswers: [],
  },
  {
    question: 'valójában, tulajdonképpen',
    description: '',
    answer: 'de hecho',
    category: 'expressions-ii',
    acceptableAnswers: [],
  },
  {
    question: 'megegyeztünk!',
    description: '',
    answer: 'de acuerdo',
    category: 'expressions-ii',
    acceptableAnswers: [],
  },
  {
    question: 'eláll a lélegzete',
    description: '',
    answer: 'dejarla sin aliento',
    category: 'expressions-ii',
    acceptableAnswers: [],
  },
  {
    question: 'miközben',
    description: '',
    answer: 'a medida que',
    category: 'expressions-ii',
    acceptableAnswers: [],
  },
  {
    question: 'sajnálatos módon...',
    description: '',
    answer: 'por desgracia',
    category: 'expressions-ii',
    acceptableAnswers: [],
  },
  {
    question: 'Torkig vagyok xy-al',
    description: '',
    answer: 'Estoy harto de',
    category: 'expressions-ii',
    acceptableAnswers: [],
  },
  {
    question: 'kaphatok egy kis kedvezményt?',
    description: '',
    answer: 'me da el pilón',
    category: 'expressions-ii',
    acceptableAnswers: [],
  },
  {
    question: 'ennek vége',
    description: 'Angol: Its over',
    answer: 'se acabó',
    category: 'expressions-ii',
    acceptableAnswers: [],
  },
  {
    question: 'semmi köze ennek ahhoz hogy...',
    description: 'Angol: it had nothing to do with...',
    answer: 'no tenía nada que ver con',
    category: 'expressions-ii',
    acceptableAnswers: [],
  },
  {
    question: 'adás vége',
    description: '',
    answer: 'acabó la transmisión',
    category: 'expressions-ii',
    acceptableAnswers: [],
  },
  {
    question: 'micsoda véletlen (egybeesés)',
    description: '',
    answer: 'que casualidad',
    category: 'expressions-ii',
    acceptableAnswers: [],
  },
  {
    question: 'vmi tűzben áll',
    description: '',
    answer: 'se prende fuego',
    category: 'expressions-ii',
    acceptableAnswers: [],
  },

  /**
   * KITCHEN
   */
  {
    question: 'pohár alátét',
    description: 'angol: coaster',
    answer: 'posavaso',
    category: 'kitchen',
    acceptableAnswers: [],
  },
  {
    question: 'tálca',
    description: '',
    answer: 'bandeja',
    category: 'kitchen',
    acceptableAnswers: [],
  },

  /**
   * FOODS
   */
  {
    question: 'konzerv',
    description: '',
    answer: 'lata',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'Rizs',
    description: '',
    answer: 'arroz',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'olivabogyó',
    description: '',
    answer: 'aceitunas',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'pezsgő',
    description: '',
    answer: 'champán',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'dzsin (ital)',
    description: '',
    answer: 'ginebra',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'vermut (ital)',
    description: '',
    answer: 'vermú',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'Citromnád',
    description: '',
    answer: 'hierba de limón',
    category: 'food',
    acceptableAnswers: [
      'zacate de limón',
    ],
  },
  {
    question: 'Darált hús',
    description: '',
    answer: 'carne picada',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'Menta',
    description: '',
    answer: 'menta',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'innivaló',
    description: '',
    answer: 'bebida',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'Fekete babpüré',
    description: '',
    answer: 'frijol colado',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'Hagyma',
    description: '',
    answer: 'cebolla',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'Sör',
    description: '',
    answer: 'chela',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'banán',
    description: '',
    answer: 'plátano',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'Szószos húsok',
    description: '',
    answer: 'guisado',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'Babérlevél',
    description: '',
    answer: 'Hoja de laurel',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'finom',
    description: '',
    answer: 'sabroso',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'virsli',
    description: '',
    answer: 'salchi',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'Pálinka',
    description: '',
    answer: 'aguardiente',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'íz',
    description: '',
    answer: 'sabor',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'köret',
    description: '',
    answer: 'complemento',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'alcoholos pohár',
    description: '',
    answer: 'copa',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'elvitelre',
    description: '',
    answer: 'para llevar',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'ribizli',
    description: '',
    answer: 'grosella',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'reggeli',
    description: '',
    answer: 'desayunar',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'ebéd',
    description: '',
    answer: 'almuerzo',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'vacsora',
    description: '',
    answer: 'cena',
    category: 'food',
    acceptableAnswers: [],
  },
  {
    question: 'táplálkozás',
    description: '',
    answer: 'nutrición',
    category: 'food',
    acceptableAnswers: [],
  },

  /**
   * MEXICAN-DISHES
   */
  {
    question: 'tépett húsos taco',
    description: '',
    answer: 'taco birria',
    category: 'mexican-dishes',
    acceptableAnswers: [],
  },
  {
    question: 'Karamellás cukor',
    description: '',
    answer: 'Muégano',
    category: 'mexican-dishes',
    acceptableAnswers: [],
  },
  {
    question: 'Mexikói lime-os húsleves',
    description: '',
    answer: 'consome',
    category: 'mexican-dishes',
    acceptableAnswers: [],
  },
  {
    question: 'gulyás szerű leves',
    description: '',
    answer: 'birria',
    category: 'mexican-dishes',
    acceptableAnswers: [],
  },
  {
    question: 'lencsefőzelék',
    description: '',
    answer: 'lenteja',
    category: 'mexican-dishes',
    acceptableAnswers: [],
  },
  {
    question: 'hangya tojásos étel',
    description: '',
    answer: 'escamoles',
    category: 'mexican-dishes',
    acceptableAnswers: [],
  },
  {
    question: 'Corn-dog',
    description: '',
    answer: 'banderilla',
    category: 'mexican-dishes',
    acceptableAnswers: [],
  },

  /**
   * PRONOUN
   */
  {
    question: 'Ez',
    description: 'tárgyra - nő',
    answer: 'esta',
    category: 'pronouns',
    acceptableAnswers: [],
  },
  {
    question: 'Ez',
    description: 'tárgyra - férfi',
    answer: 'este',
    category: 'pronouns',
    acceptableAnswers: [],
  },
  {
    question: 'Ez',
    description: 'bizonytalan nemnél',
    answer: 'esto',
    category: 'pronouns',
    acceptableAnswers: [],
  },
  {
    question: 'én',
    description: 'direct object',
    answer: 'me',
    category: 'pronouns',
    acceptableAnswers: [],
  },
  {
    question: 'te',
    description: 'direct object',
    answer: 'te',
    category: 'pronouns',
    acceptableAnswers: [],
  },
  {
    question: 'ez / ő (maszkulin)',
    description: 'direct object',
    answer: 'lo',
    category: 'pronouns',
    acceptableAnswers: [],
  },
  {
    question: 'ez / ő (feminin)',
    description: 'direct object',
    answer: 'la',
    category: 'pronouns',
    acceptableAnswers: [],
  },
  {
    question: 'mi',
    description: 'direct object',
    answer: 'nos',
    category: 'pronouns',
    acceptableAnswers: [],
  },
  {
    question: 'ti',
    description: 'direct object',
    answer: 'os',
    category: 'pronouns',
    acceptableAnswers: [],
  },
  {
    question: 'ők / azok (maszkulin)',
    description: 'direct object',
    answer: 'los',
    category: 'pronouns',
    acceptableAnswers: [],
  },
  {
    question: 'ők / azok (feminin)',
    description: 'direct object',
    answer: 'las',
    category: 'pronouns',
    acceptableAnswers: [],
  },
  {
    question: 'azt / az',
    description: '',
    answer: 'aquel',
    category: 'pronouns',
    acceptableAnswers: [],
  },
  {
    question: 'nobody',
    description: '',
    answer: 'nadie',
    category: 'pronouns',
    acceptableAnswers: [],
  },

  /**
   * PREPOSITIONS
   */
  {
    question: 'úgy smint, hasonlóan',
    description: '',
    answer: 'como',
    category: 'prepositions',
    acceptableAnswers: [],
  },
  {
    question: 'között',
    description: '',
    answer: 'entre',
    category: 'prepositions',
    acceptableAnswers: [],
  },
  {
    question: 'sőt mi több // mellesleg',
    description: '',
    answer: 'además',
    category: 'prepositions',
    acceptableAnswers: [],
  },
  {
    question: 'velem',
    description: '',
    answer: 'conmigo',
    category: 'prepositions',
    acceptableAnswers: [],
  },

  /**
   * ADVERBS
   */
  {
    question: 'Tehát',
    description: '',
    answer: 'así',
    category: 'adverb',
    acceptableAnswers: [],
  },
  {
    question: 'előtt (hely)',
    description: '',
    answer: 'Delante',
    category: 'adverb',
    acceptableAnswers: [],
  },
  {
    question: 'még',
    description: '',
    answer: 'aún',
    category: 'adverb',
    acceptableAnswers: [],
  },
  {
    question: 'talán',
    description: 'adverb',
    answer: 'Quizá',
    category: 'adverb',
    acceptableAnswers: [],
  },
  {
    question: 'túl sok',
    description: '',
    answer: 'demasiados',
    category: 'adverb',
    acceptableAnswers: [],
  },
  {
    question: 'sem',
    description: 'neighter',
    answer: 'ni',
    category: 'adverb',
    acceptableAnswers: [],
  },
  {
    question: '-ról, ről',
    description: '',
    answer: 'acerca de',
    category: 'adverb',
    acceptableAnswers: [],
  },
  {
    question: 'akkor // ezután',
    description: '',
    answer: 'entonces',
    category: 'adverb',
    acceptableAnswers: [],
  },
  {
    question: 'Érdekesen,...',
    description: '',
    answer: 'curiosamente',
    category: 'adverb',
    acceptableAnswers: [],
  },

  /**
   * CONJUNCTION
   */
  {
    question: 'hanem',
    description: 'pl.: nem egy, nem kettő, hanem három',
    answer: 'sino',
    category: 'conjunction',
    acceptableAnswers: [],
  },

  /**
   * MOVEMENTS
   */
  {
    question: 'Követem',
    description: '',
    answer: 'Sigo',
    category: 'movements',
    acceptableAnswers: [],
  },
  {
    question: 'ugrás',
    description: '',
    answer: 'saltó',
    category: 'movements',
    acceptableAnswers: [],
  },
  {
    question: 'ülni',
    description: '',
    answer: 'sentar',
    category: 'movements',
    acceptableAnswers: [],
  },
  {
    question: 'fordulni',
    description: '',
    answer: 'girar',
    category: 'movements',
    acceptableAnswers: [],
  },

  /**
   * COLORS
   */
  {
    question: 'piros',
    description: '',
    answer: 'rojo',
    category: 'colors',
    acceptableAnswers: [],
  },
  {
    question: 'sárga',
    description: '',
    answer: 'amarillo',
    category: 'colors',
    acceptableAnswers: [],
  },
  {
    question: 'szürke',
    description: '',
    answer: 'gris',
    category: 'colors',
    acceptableAnswers: [],
  },
  {
    question: 'szín',
    description: '',
    answer: 'color',
    category: 'colors',
    acceptableAnswers: [],
  },
  {
    question: 'lila',
    description: '',
    answer: 'morada',
    category: 'colors',
    acceptableAnswers: [],
  },

  /**
   * BUSINESS
   */
  {
    question: 'Értékel',
    description: '',
    answer: 'valorar',
    category: 'business',
    acceptableAnswers: [],
  },
  {
    question: 'pószter, hírdetőtábla',
    description: '',
    answer: 'cartel',
    category: 'business',
    acceptableAnswers: [],
  },
  {
    question: 'Elérhető - teljesíthető',
    description: '',
    answer: 'factible',
    category: 'business',
    acceptableAnswers: [],
  },
  {
    question: 'Befektetés',
    description: '',
    answer: 'inversión',
    category: 'business',
    acceptableAnswers: [],
  },
  {
    question: 'Befektetsz',
    description: '',
    answer: 'inviertes',
    category: 'business',
    acceptableAnswers: [],
  },
  {
    question: 'Rizikó',
    description: '',
    answer: 'riesgo',
    category: 'business',
    acceptableAnswers: [],
  },
  {
    question: 'veszít',
    description: '',
    answer: 'perder',
    category: 'business',
    acceptableAnswers: [],
  },
  {
    question: 'része valaminek',
    description: '',
    answer: 'parte',
    category: 'business',
    acceptableAnswers: [],
  },
  {
    question: 'bankkártya',
    description: '',
    answer: 'tarjeta de crédito',
    category: 'business',
    acceptableAnswers: [],
  },
  {
    question: 'készpénz',
    description: '',
    answer: 'efectivo',
    category: 'business',
    acceptableAnswers: [],
  },
  {
    question: 'ingatlan',
    description: '',
    answer: 'propiedad inmueble',
    category: 'business',
    acceptableAnswers: [],
  },
  {
    question: 'zárás',
    description: '',
    answer: 'cierre',
    category: 'business',
    acceptableAnswers: [],
  },
  {
    question: 'Körülbelüli árajánlat',
    description: '',
    answer: 'presupuesto',
    category: 'business',
    acceptableAnswers: [],
  },
  {
    question: 'teendő',
    description: '',
    answer: 'pendiente',
    category: 'business',
    acceptableAnswers: [],
  },

  /**
   * TIME
   */
  {
    question: 'néha',
    description: 'nem unas-al',
    answer: 'algunas veces',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'korszak',
    description: '',
    answer: 'época',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'Minden pillanatban',
    description: '',
    answer: 'a cada instante',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'hétfő',
    description: '',
    answer: 'lunes',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'Kedd',
    description: '',
    answer: 'martes',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'Szerda',
    description: '',
    answer: 'Miércoles',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'Csütörtök',
    description: '',
    answer: 'jueves',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'péntek',
    description: '',
    answer: 'viernes',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'Szombat',
    description: '',
    answer: 'Sábado',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'Vasárnap',
    description: '',
    answer: 'domingo',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'örökké',
    description: '',
    answer: 'siempre',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'soha',
    description: '',
    answer: 'nunca',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'nem gyakran',
    description: '',
    answer: 'no suele',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'előtte',
    description: '',
    answer: 'antes',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'hosszútáv',
    description: '',
    answer: 'largo plazo',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'néha',
    description: '',
    answer: 'a veces',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'soha',
    description: '',
    answer: 'jamás',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'ideiglenesen // egy darabig',
    description: '',
    answer: 'temporada',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'jövő',
    description: '',
    answer: 'futura',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'múlt',
    description: '',
    answer: 'pasado',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'jelen',
    description: '',
    answer: 'presente',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'később // hamar',
    description: '1',
    answer: 'luego',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'időpont / dátum',
    description: '',
    answer: 'cita',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'éjfél',
    description: '',
    answer: 'medianoche',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'amíg (until)',
    description: '',
    answer: 'hasta',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'később',
    description: '2',
    answer: 'después',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'vmi után',
    description: '',
    answer: 'tras',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'ősi',
    description: '',
    answer: 'antigua',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'végtelen / örök',
    description: '',
    answer: 'eterno',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'every time',
    description: '',
    answer: 'cada vez',
    category: 'time',
    acceptableAnswers: [],
  },
  {
    question: 'közeli (időben), friss',
    description: '',
    answer: 'reciente',
    category: 'time',
    acceptableAnswers: [],
  },

  /**
   * ONLINE SHOPPING
   */
  {
    question: 'Már meg is érkezett? (csomagszállító)',
    description: '',
    answer: 'ya estás aqui',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'részletek',
    description: '',
    answer: 'detalles',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'Kosárhoz ad',
    description: '',
    answer: 'Agregar al carrito',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'Vásárlás',
    description: '',
    answer: 'Comprar',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'raktáron van',
    description: '',
    answer: 'stock disponible',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'eladott',
    description: '',
    answer: 'vendido',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'Best seller',
    description: '',
    answer: 'más vendido',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'otthon (szállítási cím)',
    description: '',
    answer: 'domicilio',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'Szállítási lehetőségek',
    description: '',
    answer: 'opciones de envío',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'Utalás',
    description: '',
    answer: 'transferencia electrónica',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'Mennyiség',
    description: '',
    answer: 'cantidad',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'Talál',
    description: '',
    answer: 'encontrar',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'elküldeni vmit',
    description: 'pl.: csomag, levél',
    answer: 'enviar',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'csomag',
    description: '',
    answer: 'paquete',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'cím',
    description: '',
    answer: 'dirección',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'feladó',
    description: '',
    answer: 'remitente',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'címzett',
    description: '',
    answer: 'destinatario',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'boríték',
    description: '',
    answer: 'sabro',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'csomag biztosítás',
    description: '',
    answer: 'protección de envío',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'érték',
    description: '',
    answer: 'valor',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'összefoglal',
    description: 'pl.: egy mondatban hogy mi van a csomagban',
    answer: 'resumir',
    category: 'online-shopping',
    acceptableAnswers: [],
  },
  {
    question: 'tartalom',
    description: '',
    answer: 'contenido',
    category: 'online-shopping',
    acceptableAnswers: [],
  },

  /**
   * FEELINGS
   */
  {
    question: 'vágy',
    description: '',
    answer: 'ganas',
    category: 'feelings',
    acceptableAnswers: [],
  },
  {
    question: 'boldog',
    description: '',
    answer: 'contenta',
    category: 'feelings',
    acceptableAnswers: [],
  },
  {
    question: 'fájdalom',
    description: '',
    answer: 'pena',
    category: 'feelings',
    acceptableAnswers: [],
  },
  {
    question: 'Tele vagyok',
    description: '',
    answer: 'lleno',
    category: 'feelings',
    acceptableAnswers: [],
  },
  {
    question: 'Fáradt vagyok',
    description: '',
    answer: 'cansado',
    category: 'feelings',
    acceptableAnswers: [],
  },
  {
    question: 'Éhes',
    description: '',
    answer: 'hambre',
    category: 'feelings',
    acceptableAnswers: [],
  },
  {
    question: 'kétség',
    description: '',
    answer: 'duda',
    category: 'feelings',
    acceptableAnswers: [],
  },
  {
    question: 'szégyen',
    description: '',
    answer: 'vergüenza',
    category: 'feelings',
    acceptableAnswers: [],
  },
  {
    question: 'sajnálat',
    description: '',
    answer: 'lástima',
    category: 'feelings',
    acceptableAnswers: [],
  },
  {
    question: 'szomjúság',
    description: '',
    answer: 'sed',
    category: 'feelings',
    acceptableAnswers: [],
  },
  {
    question: 'szerelmi bánat // heartbreak',
    description: '',
    answer: 'desamor',
    category: 'feelings',
    acceptableAnswers: [],
  },
  {
    question: 'szomorúság',
    description: '',
    answer: 'tristeza',
    category: 'feelings',
    acceptableAnswers: [],
  },

  /**
   * BASIC ADJECTIVES
   */
  {
    question: 'Csökkenő',
    description: '',
    answer: 'bajón',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'érdekes',
    description: '',
    answer: 'excitante',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'egyszerű',
    description: '',
    answer: 'sencilla',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'fiatal',
    description: '',
    answer: 'joven',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'öreg',
    description: 'főnévként öregemként is használható',
    answer: 'viejo',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'minden egyes // each',
    description: '',
    answer: 'cada',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'legjobb',
    description: '',
    answer: 'mejor',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'Nagyobb',
    description: '',
    answer: 'mayor',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'Gazdag',
    description: '',
    answer: 'rico',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'malac',
    description: 'melléknévként',
    answer: 'puerco',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'megbecsült',
    description: '',
    answer: 'honrado',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'bármelyik',
    description: '',
    answer: 'cualquier',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'törött',
    description: '',
    answer: 'agrietado',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'gusztustalan',
    description: '',
    answer: 'asco',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'furcsa',
    description: '',
    answer: 'extraño',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'lusta',
    description: '',
    answer: 'perezoso',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'lusta',
    description: 'mexicano',
    answer: 'floja',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'kemény',
    description: '',
    answer: 'dura',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'számos',
    description: '',
    answer: 'numeroso',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'piszkos',
    description: '',
    answer: 'cochinada',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'rosszabb',
    description: '',
    answer: 'peor',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },
  {
    question: 'csúnya',
    description: '',
    answer: 'fea',
    category: 'basic-adjectives',
    acceptableAnswers: [],
  },

  /**
   * ADJECTIVES FOR PEOPLE
   */
  {
    question: 'kapzsi',
    description: '',
    answer: 'avaro',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'megbántott / sértett',
    description: '',
    answer: 'dolido',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'kegyetlen / gonosz',
    description: '',
    answer: 'cruel',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'annoyed',
    description: '',
    answer: 'enojada',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'egyedülálló',
    description: '',
    answer: 'soltero',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'különc',
    description: '',
    answer: 'excéntrico',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'izgatott',
    description: '',
    answer: 'excitada',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'kimerült',
    description: '',
    answer: 'agotada',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'osztoba // bugyuta',
    description: '',
    answer: 'tonto',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'vicces',
    description: '',
    answer: 'divertido',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'perverz',
    description: '',
    answer: 'pervertida',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'kétnyelvű',
    description: '',
    answer: 'bilingüe',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'félénk',
    description: '',
    answer: 'timido',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'nagylelkű',
    description: '',
    answer: 'generoso',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'nyers / durva',
    description: '',
    answer: 'bravo',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'bátor',
    description: '',
    answer: 'valiente',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'szegény',
    description: '',
    answer: 'pobre',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'kedves',
    description: '',
    answer: 'amable',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'kalandvágyó',
    description: '',
    answer: 'aventurero',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'szigorú',
    description: '',
    answer: 'estricto',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'alázatos',
    description: '',
    answer: 'humilde',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'féltékeny',
    description: '',
    answer: 'celosa',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'vonzó (nő)',
    description: '',
    answer: 'atractiva',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'sokat tanuló',
    description: 'nem sértő',
    answer: 'estudioso',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'izzadt',
    description: '',
    answer: 'sudoroso',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'elkeseredett',
    description: '',
    answer: 'amargado',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'aggódó',
    description: '',
    answer: 'ansioso',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },
  {
    question: 'bolond',
    description: '',
    answer: 'imbécil',
    category: 'adjectives-for-people',
    acceptableAnswers: [],
  },

  /**
   * ADVANCED ADJECTIVES I
   */
  {
    question: 'csempészet',
    description: '',
    answer: 'contrabando',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'elképesztő / nagyszerű',
    description: '',
    answer: 'estupenda',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'leragadt',
    description: '',
    answer: 'metido',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'hasznos',
    description: '',
    answer: 'útil',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'elkapta / fogságba esett',
    description: '',
    answer: 'atrapado',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'szép / kellemes',
    description: 'angol: nice',
    answer: 'agradable',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'csillogó',
    description: 'Angol: gleaming',
    answer: 'relucientes',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'kifosztott',
    description: '',
    answer: 'despojo',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'hátborzongató',
    description: '',
    answer: 'espeluznante',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'képzeletbeli',
    description: '',
    answer: 'imaginaria',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'omlós // kedves, lágy',
    description: '',
    answer: 'tierno',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'elhúzódott, elnyújtött',
    description: '',
    answer: 'prolongado',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'luxus',
    description: '',
    answer: 'lujo',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'pofátlan / szemtelen / szemérmetlen',
    description: '',
    answer: 'descarado',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'rövid, rövidke, röviden',
    description: 'pl.: történetre',
    answer: 'brevemente',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'különböző',
    description: '',
    answer: 'distinto',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'sármos / elbűvölő',
    description: '',
    answer: 'encantador',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'rendkívül',
    description: '',
    answer: 'extremadamente',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'szeretteljes',
    description: '',
    answer: 'cariñosa',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'alkalmas',
    description: '',
    answer: 'adecuado',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'stagnáló',
    description: 'angol: stagnant',
    answer: 'estancado',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'felszabadító',
    description: '',
    answer: 'libertador',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'káprázatos',
    description: '',
    answer: 'magnífica',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'a rossz (személyt / tárgyat)',
    description: '',
    answer: 'equivocada',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'előző',
    description: '',
    answer: 'anterior',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },
  {
    question: 'bizonyos',
    description: '',
    answer: 'ciertas',
    category: 'advanced-adjectives-i',
    acceptableAnswers: [],
  },

  /**
   * FASHION
   */
  {
    question: 'ruha',
    description: '',
    answer: 'ropa',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'válltáska',
    description: '',
    answer: 'bolso',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'elegáns öltözet',
    description: 'angol: dress',
    answer: 'vestido',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'lyuk',
    description: '',
    answer: 'hoyo',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'szallag',
    description: '',
    answer: 'cinta',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'blúz',
    description: '',
    answer: 'blusa',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'alsónemű',
    description: '',
    answer: 'ropa interior',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'öltöny',
    description: '',
    answer: 'traje',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'gyűrű',
    description: '',
    answer: 'anillo',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'kendő',
    description: '',
    answer: 'paliacate',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'szoknya',
    description: '',
    answer: 'falda',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'ing',
    description: '',
    answer: 'camisa',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'méret',
    description: '',
    answer: 'talla',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'kalap',
    description: '',
    answer: 'sombrero',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'rövidnadrág',
    description: '',
    answer: 'pantalones cortos',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'fülbevalók',
    description: '',
    answer: 'aretes',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: '(haj) fonás',
    description: '',
    answer: 'trenza',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'póló',
    description: '',
    answer: 'camiseta',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'napszemüveg',
    description: '',
    answer: 'gafas de sol',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'boot',
    description: '',
    answer: 'bota',
    category: 'fashion',
    acceptableAnswers: [],
  },
  {
    question: 'sapka',
    description: '',
    answer: 'gorra',
    category: 'fashion',
    acceptableAnswers: [],
  },

  /**
   * NATURE
   */
  {
    question: 'Kecske',
    description: '',
    answer: 'chivo',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'majom',
    description: '',
    answer: 'mono',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'tűz',
    description: '',
    answer: 'fuego',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'fényesség / ragyogás',
    description: '',
    answer: 'el brillo',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'zsiráf',
    description: '',
    answer: 'jirafa',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'bolygó',
    description: '',
    answer: 'planeta',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'shades of // vmi árnyalatai',
    description: '',
    answer: 'matices de',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'szél',
    description: '',
    answer: 'viento',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'Szöcske',
    description: '',
    answer: 'grillo',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'póni',
    description: '',
    answer: 'caballito',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'polip',
    description: '',
    answer: 'pulpo',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'Malac',
    description: '',
    answer: 'cerdo',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'hegység',
    description: '',
    answer: 'sierra',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'csillagok',
    description: '',
    answer: 'estrellas',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'faág',
    description: '',
    answer: 'rama',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'naplemente',
    description: '',
    answer: 'atardecer',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'pálma',
    description: '',
    answer: 'palmera',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'égbolt',
    description: '',
    answer: 'firmamento',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'szellő',
    description: '',
    answer: 'brisa',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'vihar',
    description: '',
    answer: 'tormenta',
    category: 'nature',
    acceptableAnswers: [],
  },
  {
    question: 'eső',
    description: '',
    answer: 'lluvia',
    category: 'nature',
    acceptableAnswers: [],
  },

  /**
   * OFFICE
   */
  {
    question: 'szék',
    description: '',
    answer: 'silla',
    category: 'office',
    acceptableAnswers: [],
  },
  {
    question: 'whiteboard',
    description: '',
    answer: 'pizarron',
    category: 'office',
    acceptableAnswers: [],
  },
  {
    question: 'toll',
    description: '',
    answer: 'pluma',
    category: 'office',
    acceptableAnswers: [],
  },
  {
    question: 'kirendeltség',
    description: 'angol - branch',
    answer: 'sucursal',
    category: 'office',
    acceptableAnswers: [],
  },

  /**
   * ACTIVITIES
   */
  {
    question: 'falmászás',
    description: '',
    answer: 'escalada de pared',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'tréning // edzés',
    description: '',
    answer: 'entrenamiento',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'kürt',
    description: '',
    answer: 'trompa',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'esküvő',
    description: '',
    answer: 'boda',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'basszusgitár',
    description: '',
    answer: 'el bajo',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'sport',
    description: '',
    answer: 'deporte',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'hiking',
    description: '',
    answer: 'excursionismo',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'sörözés',
    description: '',
    answer: 'cheleando',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'utazás',
    description: '',
    answer: 'viaje',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'hobbi',
    description: '',
    answer: 'pasatiempo',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'játszik / előad',
    description: '',
    answer: 'tocar',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'énekel',
    description: '',
    answer: 'cantar',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'dal',
    description: '',
    answer: 'canción',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'lovagolni',
    description: 'régies',
    answer: 'jinetear',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'lovagolni',
    description: 'gyakorlatban használt',
    answer: 'cabalgar',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'szivar',
    description: '',
    answer: 'puro',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'chatelni',
    description: '',
    answer: 'charlar',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'dalszöveg',
    description: '',
    answer: 'letra',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'vezetni',
    description: '',
    answer: 'manejar',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'vezetői engedély',
    description: '',
    answer: 'permiso de conducir',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'olvasni',
    description: '',
    answer: 'leer',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'beszélgetni',
    description: '',
    answer: 'platicar',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'úszni',
    description: '',
    answer: 'nadar',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'futni',
    description: '',
    answer: 'correr',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'kocogni',
    description: '',
    answer: 'trotar',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'kötni',
    description: '',
    answer: 'tejer',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'görkorcsolya',
    description: '',
    answer: 'patines',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'kör',
    description: 'pl.: versenyen',
    answer: 'vuelta',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'találkozás',
    description: '',
    answer: 'encuentro',
    category: 'activities',
    acceptableAnswers: [],
  },
  {
    question: 'lehetőség / történés / esemény',
    description: '',
    answer: 'ocasión',
    category: 'activities',
    acceptableAnswers: [],
  },

  /**
   * BODY PARTS
   */
  {
    question: 'Haj',
    description: '',
    answer: 'cabello',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'vér',
    description: '',
    answer: 'sangre',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'sebhely',
    description: '',
    answer: 'cicatriz',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'szemöldök',
    description: '',
    answer: 'ceja',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'arc',
    description: '',
    answer: 'la cara',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'ujj',
    description: '',
    answer: 'dedo',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'derék',
    description: '',
    answer: 'cintura',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'Szőr',
    description: '',
    answer: 'pelo',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'vállak',
    description: '',
    answer: 'hombros',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'áll',
    description: '',
    answer: 'barbilla',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'pofazacskó',
    description: '',
    answer: 'mejillas',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'ajkak',
    description: '',
    answer: 'labios',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'orr',
    description: '',
    answer: 'nariz',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'kar',
    description: '',
    answer: 'brazo',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'fül',
    description: '',
    answer: 'oreja',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'lábak',
    description: '',
    answer: 'piernas',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'gyomor',
    description: '',
    answer: 'stomago',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'nyak',
    description: '',
    answer: 'cuello',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'fej',
    description: '',
    answer: 'cabeza',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'has',
    description: '',
    answer: 'vientre',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'hónaalj',
    description: '',
    answer: 'axila',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'száj',
    description: '',
    answer: 'boca',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'hólyag',
    description: 'bladder',
    answer: 'vejiga',
    category: 'body-parts',
    acceptableAnswers: [],
  },
  {
    question: 'bél',
    description: '',
    answer: 'intestino',
    category: 'body-parts',
    acceptableAnswers: [],
  },

  /**
   * SLANGS
   */
  {
    question: 'barna bőrű sötét hajú nő',
    description: '',
    answer: 'morena',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'close family',
    description: '',
    answer: 'muégano',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'Milyen menő',
    description: '',
    answer: 'que chido',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'kibaszott',
    description: '',
    answer: 'pinche',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'Még mit nem!',
    description: '',
    answer: 'chale',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'Mi a helyzet?',
    description: '',
    answer: 'que onda',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'Kajakóma',
    description: '',
    answer: 'mal de puerco',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'szexelni',
    description: '',
    answer: 'coger',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'fasz',
    description: 'yucateco slang',
    answer: 'pichón',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'Mizu?',
    description: 'Yucateco szleng csak szóban',
    answer: 'quiubole',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'son of a bitch',
    description: 'Yucateco',
    answer: 'pelana',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'son of a bitch',
    description: '',
    answer: 'hijo de la chingada',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'Fuck!',
    description: '',
    answer: 'carajo',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'A fenébe!',
    description: '',
    answer: 'coño',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'Micsoda rosszfiú',
    description: '',
    answer: 'que chingón',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'Ledzsaló',
    description: '',
    answer: 'bajón',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'seggfej',
    description: '',
    answer: 'cabrón',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'guadalajarai emberek',
    description: '',
    answer: 'Tapatio',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'guadalajarai emberek',
    description: '',
    answer: 'Tapatio',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },
  {
    question: 'nagy üveges sör',
    description: '',
    answer: 'caguama',
    category: 'mexican-slangs',
    acceptableAnswers: [],
  },

  /**
   * MEXICAN SLANGS II
   */
  {
    question: 'Mexikó városiak',
    description: '',
    answer: 'chilangos',
    category: 'mexican-slangs-ii',
    acceptableAnswers: [],
  },
  {
    question: 'számítógép',
    description: '',
    answer: 'computadora',
    category: 'mexican-slangs-ii',
    acceptableAnswers: [],
  },
  {
    question: 'go ahead',
    description: '',
    answer: 'propio',
    category: 'mexican-slangs-ii',
    acceptableAnswers: [],
  },
  {
    question: 'meztelen nő',
    description: 'szleng',
    answer: 'encuerada',
    category: 'mexican-slangs-ii',
    acceptableAnswers: [],
  },

  /**
   * CITY
   */
  {
    question: 'macskakő',
    description: '',
    answer: 'adoquin',
    category: 'city',
    acceptableAnswers: [],
  },
  {
    question: 'börtöncella',
    description: '',
    answer: 'jaula',
    category: 'city',
    acceptableAnswers: [],
  },
  {
    question: 'mozgólépcső',
    description: '',
    answer: 'escalera',
    category: 'city',
    acceptableAnswers: [],
  },
  {
    question: 'szökőkút',
    description: '',
    answer: 'fuente',
    category: 'city',
    acceptableAnswers: [],
  },
  {
    question: 'pad',
    description: '',
    answer: 'banco',
    category: 'city',
    acceptableAnswers: [],
  },
  {
    question: 'mászóka',
    description: '',
    answer: 'trepador',
    category: 'city',
    acceptableAnswers: [],
  },
  {
    question: 'libikóka',
    description: '',
    answer: 'sube y baja',
    category: 'city',
    acceptableAnswers: [],
  },
  {
    question: 'csúszda',
    description: '',
    answer: 'tobogán',
    category: 'city',
    acceptableAnswers: [],
  },
  {
    question: 'fa házikó',
    description: '',
    answer: 'casa de madera',
    category: 'city',
    acceptableAnswers: [],
  },
  {
    question: 'kuka (papíroknak vagy beltéren)',
    description: '',
    answer: 'papelera',
    category: 'city',
    acceptableAnswers: [],
  },
  {
    question: 'otthon',
    description: '',
    answer: 'hogar',
    category: 'city',
    acceptableAnswers: [],
  },
  {
    question: 'híd',
    description: '',
    answer: 'puente',
    category: 'city',
    acceptableAnswers: [],
  },
  {
    question: 'háztömb',
    description: '',
    answer: 'cuadra',
    category: 'city',
    acceptableAnswers: [],
  },
  {
    question: 'gabona tároló',
    description: '',
    answer: 'almacén de granos',
    category: 'city',
    acceptableAnswers: [],
  },

  /**
   * PEOPLE
   */
  {
    question: 'ügyvéd',
    description: '',
    answer: 'abogada',
    category: 'people',
    acceptableAnswers: [],
  },
  {
    question: 'építész',
    description: '',
    answer: 'arquitecto',
    category: 'people',
    acceptableAnswers: [],
  },
  {
    question: 'zaklató',
    description: '',
    answer: 'acosador',
    category: 'people',
    acceptableAnswers: [],
  },
  {
    question: 'tengerész',
    description: '',
    answer: 'marinero',
    category: 'people',
    acceptableAnswers: [],
  },
  {
    question: 'jövendőmondó',
    description: '',
    answer: 'adivino',
    category: 'people',
    acceptableAnswers: [],
  },
  {
    question: 'riporter',
    description: '',
    answer: 'reportera',
    category: 'people',
    acceptableAnswers: [],
  },
  {
    question: 'számító ember',
    description: '',
    answer: 'convenenciera',
    category: 'people',
    acceptableAnswers: [],
  },
  {
    question: 'Csomó üzenetet küldő ember',
    description: '',
    answer: 'atosigador',
    category: 'people',
    acceptableAnswers: [],
  },
  {
    question: 'lovas ember',
    description: '',
    answer: 'jinete',
    category: 'people',
    acceptableAnswers: [],
  },
  {
    question: 'kíséret',
    description: '',
    answer: 'acompaña',
    category: 'people',
    acceptableAnswers: [],
  },
  {
    question: 'pszichológus',
    description: '',
    answer: 'psicólogo',
    category: 'people',
    acceptableAnswers: [],
  },
  {
    question: 'tanácsadó',
    description: 'angol: adviser',
    answer: 'consejero',
    category: 'people',
    acceptableAnswers: [],
  },
  {
    question: 'vöröske',
    description: '',
    answer: 'Pelirrojo',
    category: 'people',
    acceptableAnswers: [],
  },

  /**
   * TECHNOLOGY
   */
  {
    question: 'gép',
    description: '',
    answer: 'máquina',
    category: 'technology',
    acceptableAnswers: [],
  },
  {
    question: 'eszköz',
    description: '',
    answer: 'herramienta',
    category: 'technology',
    acceptableAnswers: [],
  },
  {
    question: 'ki/bekapcsoló gomb',
    description: '',
    answer: 'el interruptor',
    category: 'technology',
    acceptableAnswers: [],
  },

  /**
   * GADGETS AND SCIENCE SCHOOL
   */
  {
    question: 'robotkar',
    description: '',
    answer: 'brazo robótico',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'órarend',
    description: '',
    answer: 'horario',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'jog',
    description: '',
    answer: 'derecho',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'fizika',
    description: '',
    answer: 'física',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'kormány(lapát))',
    description: '',
    answer: 'timón',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'fékek',
    description: '',
    answer: 'frenos',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'hajtóanyag',
    description: '',
    answer: 'propulsores',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'tartószerkezet',
    description: '',
    answer: 'estructura de soporte',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'kabin',
    description: '',
    answer: 'cabina',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'folyékony oxigén tartály',
    description: '',
    answer: 'tanque de oxigeno liquido',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'műhold',
    description: '',
    answer: 'satéllite',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'elektronikus kütyü',
    description: '',
    answer: 'aparato electrónico',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'tudomány',
    description: '',
    answer: 'ciencia',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'bluetoothos hangszóró',
    description: '',
    answer: 'bocina',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'kajásdoboz',
    description: '',
    answer: 'fiambrera',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'könyvek',
    description: '',
    answer: 'libros',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'földgömb',
    description: '',
    answer: 'globo terráqueo',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'számítógép',
    description: 'Spanyol',
    answer: 'ordenador',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'irodalom',
    description: '',
    answer: 'literatura',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'feltöltök',
    description: '',
    answer: 'subo',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },
  {
    question: 'dolgozat',
    description: '',
    answer: 'trabajo',
    category: 'gadgets-science-school',
    acceptableAnswers: [],
  },

  /**
   * TRANSPORTATION
   */
  {
    question: 'irány / útvonal',
    description: '',
    answer: 'rumbo',
    category: 'transportation',
    acceptableAnswers: [],
  },
  {
    question: 'oldal',
    description: 'pl.: az út másik oldalán vagy',
    answer: 'lado',
    category: 'transportation',
    acceptableAnswers: [],
  },
  {
    question: 'indítás // start',
    description: '',
    answer: 'arranque',
    category: 'transportation',
    acceptableAnswers: [],
  },

  /**
   * TRAVEL
   */
  {
    question: 'gurulós bőrönd',
    description: '',
    answer: 'equipaje con ruedas',
    category: 'travel',
    acceptableAnswers: [],
  },
  {
    question: 'robogó',
    description: '',
    answer: 'motoneta',
    category: 'travel',
    acceptableAnswers: [],
  },
  {
    question: 'tánctér',
    description: 'Angol: dance floor',
    answer: 'pista de baile',
    category: 'travel',
    acceptableAnswers: [],
  },
  {
    question: 'hátizsák',
    description: '',
    answer: 'mochila',
    category: 'travel',
    acceptableAnswers: [],
  },
  {
    question: 'bőrönd',
    description: '',
    answer: 'maleta',
    category: 'travel',
    acceptableAnswers: [],
  },
  {
    question: 'homokvár',
    description: '',
    answer: 'castillo de arena',
    category: 'travel',
    acceptableAnswers: [],
  },
  {
    question: 'furgon, kisbusz',
    description: '',
    answer: 'camioneta',
    category: 'travel',
    acceptableAnswers: [],
  },
  {
    question: 'napernyő',
    description: '',
    answer: 'sombrilla',
    category: 'travel',
    acceptableAnswers: [],
  },
  {
    question: 'fürdőruha',
    description: '',
    answer: 'bañador',
    category: 'travel',
    acceptableAnswers: [],
  },
  {
    question: 'fagyi',
    description: '',
    answer: 'helado',
    category: 'travel',
    acceptableAnswers: [],
  },
  {
    question: 'hajó',
    description: '',
    answer: 'bote',
    category: 'travel',
    acceptableAnswers: [],
  },
  {
    question: 'úszógumi',
    description: '',
    answer: 'flotador',
    category: 'travel',
    acceptableAnswers: [],
  },
  {
    question: 'motorcsónak',
    description: '',
    answer: 'lancha motora',
    category: 'travel',
    acceptableAnswers: [],
  },
  {
    question: 'ladba',
    description: '',
    answer: 'pelota',
    category: 'travel',
    acceptableAnswers: [],
  },
  {
    question: 'emeletes ágy',
    description: '',
    answer: 'litera',
    category: 'travel',
    acceptableAnswers: [],
  },
  {
    question: 'távolság',
    description: 'térben és időben',
    answer: 'distante',
    category: 'travel',
    acceptableAnswers: [],
  },
  {
    question: 'autó gumi',
    description: '',
    answer: 'llanta',
    category: 'travel',
    acceptableAnswers: [],
  },

  /**
   * BODY PROCESS
   */
  {
    question: 'lélegzet',
    description: '',
    answer: 'aliento',
    category: 'body-process',
    acceptableAnswers: [],
  },
  {
    question: 'nézés',
    description: '',
    answer: 'mirada',
    category: 'body-process',
    acceptableAnswers: [],
  },

  /**
   * GRAMMAR
   */
  {
    question: 'mondás',
    description: '',
    answer: 'refran',
    category: 'grammar',
    acceptableAnswers: [],
  },
  {
    question: 'köznyelv',
    description: '',
    answer: 'coloquial',
    category: 'grammar',
    acceptableAnswers: [],
  },
  {
    question: 'káromkodás',
    description: '',
    answer: 'groserías',
    category: 'grammar',
    acceptableAnswers: [],
  },
  {
    question: 'ragozás',
    description: '',
    answer: 'conjugación',
    category: 'grammar',
    acceptableAnswers: [],
  },
  {
    question: 'mondat',
    description: '',
    answer: 'oracione',
    category: 'grammar',
    acceptableAnswers: [],
  },

  /**
   * FAMILY
   */
  {
    question: 'partner (párkapcsolatban)',
    description: '',
    answer: 'pareja',
    category: 'family',
    acceptableAnswers: [],
  },
  {
    question: 'keresztapa',
    description: '',
    answer: 'padrino',
    category: 'family',
    acceptableAnswers: [],
  },
  {
    question: 'vőfély',
    description: '',
    answer: 'padrino de boda',
    category: 'family',
    acceptableAnswers: [],
  },
  {
    question: 'férj',
    description: '',
    answer: 'marido',
    category: 'family',
    acceptableAnswers: [],
  },
  {
    question: 'nagybácsi',
    description: '',
    answer: 'tío',
    category: 'family',
    acceptableAnswers: [],
  },
  {
    question: 'menyasszony',
    description: '',
    answer: 'prometida',
    category: 'family',
    acceptableAnswers: [],
  },

  /**
   * COUNTRIES AND NATIONS
   */
  {
    question: 'skót',
    description: '',
    answer: 'escocés',
    category: 'countries-and-nations',
    acceptableAnswers: [],
  },

  /**
   * KID GAMES
   */
  {
    question: 'üveggolyó',
    description: '',
    answer: 'canica',
    category: 'kid-games',
    acceptableAnswers: [],
  },
  {
    question: 'játék',
    description: 'toy',
    answer: 'juguete',
    category: 'kid-games',
    acceptableAnswers: [],
  },
  {
    question: 'rajz',
    description: '',
    answer: 'dibujo',
    category: 'kid-games',
    acceptableAnswers: [],
  },
  {
    question: 'matrica',
    description: '',
    answer: 'pegatina',
    category: 'kid-games',
    acceptableAnswers: [],
  },
  {
    question: 'meglepetés',
    description: '',
    answer: 'sorpresa',
    category: 'kid-games',
    acceptableAnswers: [],
  },
  {
    question: 'szemfedő',
    description: '',
    answer: 'parche',
    category: 'kid-games',
    acceptableAnswers: [],
  },
  {
    question: 'plüss',
    description: 'Angol: plush',
    answer: 'peluche',
    category: 'kid-games',
    acceptableAnswers: [],
  },

  /**
   * PARTY
   */
  {
    question: 'zenegép',
    description: '',
    answer: 'rockola',
    category: 'party',
    acceptableAnswers: [],
  },
  {
    question: 'ital (alkoholos)',
    description: '',
    answer: 'trago',
    category: 'party',
    acceptableAnswers: [],
  },
  {
    question: 'élvezet / boldogság',
    description: 'angol: fun',
    answer: 'diversión',
    category: 'party',
    acceptableAnswers: [],
  },

  /**
   * RELATIONSHIP
   */
  {
    question: 'drágám',
    description: '',
    answer: 'cariño',
    category: 'relationship',
    acceptableAnswers: [],
  },
  {
    question: 'pacskolni (feneket)',
    description: 'angol: spanking',
    answer: 'nalgada',
    category: 'relationship',
    acceptableAnswers: [],
  },
  {
    question: 'házasság',
    description: '',
    answer: 'matrimonio',
    category: 'relationship',
    acceptableAnswers: [],
  },
  {
    question: 'bébi (barátnőnek)',
    description: '',
    answer: 'nena',
    category: 'relationship',
    acceptableAnswers: [],
  },

  /**
   * SPIRITUALITY-PSYCHOLOGY
   */
  {
    question: 'jótanács',
    description: '',
    answer: 'consejo',
    category: 'spirituality-psychology',
    acceptableAnswers: [],
  },
  {
    question: 'az egyensúly',
    description: '',
    answer: 'El equilibrio',
    category: 'spirituality-psychology',
    acceptableAnswers: [],
  },
  {
    question: 'öngyilkosság',
    description: '',
    answer: 'suicida',
    category: 'spirituality-psychology',
    acceptableAnswers: [],
  },
  {
    question: 'attitűd',
    description: 'attitude',
    answer: 'actitude',
    category: 'spirituality-psychology',
    acceptableAnswers: [],
  },
  {
    question: 'üresség / vákuum',
    description: '',
    answer: 'vacío',
    category: 'spirituality-psychology',
    acceptableAnswers: [],
  },

  /**
   * ART
   */
  {
    question: 'sziluett',
    description: '',
    answer: 'silueta',
    category: 'art',
    acceptableAnswers: [],
  },

  /**
   * PUESTO
   */
  {
    question: 'készen állok az őrültségeidre',
    description: 'jelentés: késznek lenni valamire',
    answer: 'estoy puesto para todas tus locuras',
    category: 'puesto',
    acceptableAnswers: [],
  },
  {
    question: 'Tegnap vettem egy válltáskát egy bódéban a téren',
    description: 'jelentés: piaci bódé / pult',
    answer: 'ayer compré un bolso en un puesto de la plaza',
    category: 'puesto',
    acceptableAnswers: [],
  },
  {
    question: 'megkaptam a munkát',
    description: 'jelentés: munka',
    answer: 'has conseguido el puesto',
    category: 'puesto',
    acceptableAnswers: [],
  },
  {
    question: 'ruhát hord/visel',
    description: '',
    answer: 'llevar puesto',
    category: 'puesto',
    acceptableAnswers: [],
  },
  {
    question: 'Nagyon elegáns leszel',
    description: 'jelentés: elegáns, jól öltözött',
    answer: 'vas muy bien puesto',
    category: 'puesto',
    acceptableAnswers: [],
  },
  {
    question: 'Ana nagyon profi matekból',
    description: 'jelentés: nagy tudása van vmiben / expert',
    answer: 'Ana está muy puesta en matemáticas',
    category: 'puesto',
    acceptableAnswers: [],
  },
  {
    question: 'Tegnap Francisco nagyon beállt',
    description: 'jelentés: bedrogozva lenni',
    answer: 'Ayer Francisco iba muy puesto',
    category: 'puesto',
    acceptableAnswers: [],
  },

  /**
   * POR OR PARA
   */
  {
    question: 'uticél',
    description: '',
    answer: 'para',
    category: 'por-or-para',
    acceptableAnswers: [],
  },
  {
    question: 'célok (vmit csinálni vmiért)',
    description: '',
    answer: 'para',
    category: 'por-or-para',
    acceptableAnswers: [],
  },
  {
    question: 'címzett / fogadó fél',
    description: '',
    answer: 'para',
    category: 'por-or-para',
    acceptableAnswers: [],
  },
  {
    question: 'határidő',
    description: '',
    answer: 'para',
    category: 'por-or-para',
    acceptableAnswers: [],
  },
  {
    question: 'idő hossz',
    description: '',
    answer: 'por',
    category: 'por-or-para',
    acceptableAnswers: [],
  },
  {
    question: 'motiváció',
    description: 'pl.: Ezt érted teszem',
    answer: 'por',
    category: 'por-or-para',
    acceptableAnswers: [],
  },
  {
    question: 'váltás / csere',
    description: '',
    answer: 'por',
    category: 'por-or-para',
    acceptableAnswers: [],
  },
  {
    question: 'mód (angol by)',
    description: '',
    answer: 'por',
    category: 'por-or-para',
    acceptableAnswers: [],
  },
  {
    question: 'megyek a diszkóba',
    description: 'uticél',
    answer: 'voy para la discoteca',
    category: 'por-or-para',
    acceptableAnswers: [],
  },
  {
    question: 'felhívlak hogy beszéljünk',
    description: 'célok (vmit csinálni vmiért)',
    answer: 'estoy llamando para hablar contigo',
    category: 'por-or-para',
    acceptableAnswers: [],
  },
  {
    question: 'A kaja anyukádnak van',
    description: 'címzett / fogadó fél',
    answer: 'la comida es para tu madre',
    category: 'por-or-para',
    acceptableAnswers: [],
  },
  {
    question: 'szükségem van a pénzre hétfő',
    description: 'határidő',
    answer: 'necesito el dinero para lunes',
    category: 'por-or-para',
    acceptableAnswers: [],
  },
  {
    question: '8 órán át aludt',
    description: 'idő hossz',
    answer: 'durmió por 8 horas',
    category: 'por-or-para',
    acceptableAnswers: [],
  },
  {
    question: 'Ezt érted teszem',
    description: 'motiváció',
    answer: 'lo hago por ti',
    category: 'por-or-para',
    acceptableAnswers: [],
  },
  {
    question: 'adok neked 3 dollárt 2 euróért',
    description: 'váltás / csere',
    answer: 'te doy 3 dolares por 2 euros',
    category: 'por-or-para',
    acceptableAnswers: [],
  },
  {
    question: 'Telefonon beszélt velem',
    description: 'mód (angol by)',
    answer: 'me habló por celular',
    category: 'por-or-para',
    acceptableAnswers: [],
  },

  /**
   * MEDIA
   */
  {
    question: 'híradó',
    description: '',
    answer: 'noticiero',
    category: 'media',
    acceptableAnswers: [],
  },
  {
    question: 'szerep',
    description: 'role',
    answer: 'papel',
    category: 'media',
    acceptableAnswers: [],
  },

  /**
   * CRIME, ARMY, POLICE
   */
  {
    question: 'bűn',
    description: 'felony',
    answer: 'delito',
    category: 'crime-army-police',
    acceptableAnswers: [],
  },
  {
    question: 'segítség!',
    description: 'Drámai, nagy problémára!',
    answer: 'Auxilio',
    category: 'crime-army-police',
    acceptableAnswers: [],
  },
  {
    question: 'zűrzavar / káosz',
    description: '',
    answer: 'revoltijo',
    category: 'crime-army-police',
    acceptableAnswers: [],
  },
  {
    question: 'osztag',
    description: '',
    answer: 'pelotona',
    category: 'crime-army-police',
    acceptableAnswers: [],
  },

  /**
   * OTHERS
   */
  {
    question: 'ment // was going',
    description: '',
    answer: 'iba',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'részrehajlás / előnyben részesített',
    description: '',
    answer: 'predilección',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'Törp kuki',
    description: '',
    answer: 'pene de pitufo',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'szerencse',
    description: '',
    answer: 'suerte',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'kézfogás',
    description: '',
    answer: 'apretón de manos',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'díj / nyeremény / elismerés',
    description: 'award / prize / accolade',
    answer: 'premio',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'tudás',
    description: 'Angol: knowledge',
    answer: 'conocimiento',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'wow / jajj ne',
    description: '',
    answer: 'vaya',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'elvinni',
    description: '',
    answer: 'llevarte',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'vmi mellett',
    description: '',
    answer: 'junto a',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'Ezért / emiatt / Nos',
    description: '',
    answer: 'pues',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'tetőterasz',
    description: 'Angol: rooftop',
    answer: 'la azotea',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'épület',
    description: '',
    answer: 'edificio',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'Statisztika',
    description: '',
    answer: 'estadística',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'ilyen',
    description: 'angol: such',
    answer: 'tal',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'remélhetőleg',
    description: '',
    answer: 'ojalá',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'hang',
    description: '',
    answer: 'voz',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'tény',
    description: '',
    answer: 'hecho',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'kivéve',
    description: '',
    answer: 'salvo',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'katasztrófa',
    description: '',
    answer: 'desastre',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'gesztus',
    description: '',
    answer: 'gesto',
    category: 'others',
    acceptableAnswers: [],
  },
  /*
  {
    question: 'azért a',
    description: '',
    answer: 'por aquel',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: 'csak',
    description: 'angol: just',
    answer: 'tan',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: '',
    description: '',
    answer: '',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: '',
    description: '',
    answer: '',
    category: 'others',
    acceptableAnswers: [],
  },
  {
    question: '',
    description: '',
    answer: '',
    category: 'others',
    acceptableAnswers: [],
  },

  /** */


];
